export default {
  number(value,length,decimalPlace) {
    return decimalsFormat(value,length,decimalPlace)
  }
}
/**
 * input    小数格式
 * @param val       值
 * @param length    总长度
 * @param decimalPlace  小数位数 默认2位
 */
function decimalsFormat(val, length, decimalPlace) {
  //先把非数字的都替换掉，除了数字和小数点
  val = val.replace(/[^\d.]/g, '')
  //必须保证第一个为数字而不是小数点
  val = val.replace(/^\./g, '')
  //保证只有出现一个小数点而没有多个小数点
  val = val.replace(/\.{2,}/g, '.')
  //保证小数点只出现一次，而不能出现两次以上
  val = val.replace('.', '$#$').replace(/\./g, '').replace('$#$', '.')
  //保证只能输入两个小数
  if (decimalPlace == 1) {
    val = val.replace(/^(\-)*(\d+)\.(\d).*$/, '$1$2.$3')
  } else if (decimalPlace == 3) {
    val = val.replace(/^(\-)*(\d+)\.(\d\d\d).*$/, '$1$2.$3')
  } else if (decimalPlace == 4) {
    val = val.replace(/^(\-)*(\d+)\.(\d\d\d\d).*$/, '$1$2.$3')
  } else if (decimalPlace === 0) {
    val = val.replace(/^(\-)*(\d+).*$/, '$1$2')
  } else {
    val = val.replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3')
  }
  if ((val * 1).toFixed(0).length > length) {
    val = val.substring(0, length)
  }
  if (val == null || val == '') val = 0
  return val
}
