function request(type, url, head) {
  const xhr = new XMLHttpRequest()
  xhr.open(type, url)
  xhr.setRequestHeader('Content-Type', 'application/json;charset=UTF-8')
  xhr.setRequestHeader('x-app-key', 'xx')
  xhr.setRequestHeader('x-os', 'web')
  head && (xhr.responseType = 'blob')
  xhr.onerror = function (e) {
    throw new Error(e.stausText)
  }
  return xhr
}

export default {
  get(url, config) {
    return request('get', url, config)
  },
  post(url, config) {
    return request('post', url, config)
  }
}
