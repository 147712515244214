import calculate from '../calculate'
export default {
  getContainerTemplate(selectData) {
    let lang = selectData.lang
    return `
    <div class='BDesign__container ${selectData.screenMode}'>
        <div class="BDesign__loading"><dig class="BDesign__loading-inner"></dig></div>
        <div class="BDesign__wrapper">
          <div class="BDesign__home-wrapper show">
            <div class="BDesign__home-container">
              <div class="BDesign__home-logo"><img alt=""></div>
              <p class="BDesign__home-title">${lang.homeTitle1}</p>
              <p class="BDesign__home-title">${lang.homeTitle2}</p>
              <div class="BDesign__home-list "></div>
            </div>
          </div>
          <div class="BDesign__step-container"></div>
        </div>
        <div class="BDesign__sum">
          <div class="BDesign__warning-wrapper">
            <p class="BDesign__warning-title">${lang.warningTitle}</p>
            <div class="BDesign__warning-line" load>${lang.warningDesc1}</div>
            <div class="BDesign__warning-line" backupEnergy>${lang.warningDesc2}<span class="BDesign__warning-text">39.68kWh</span>${lang.warningDesc3}</div>
            <div class="BDesign__warning-line" surgePower>${lang.warningDesc4}<span class="BDesign__warning-text">18kW</span>${lang.warningDesc5}</div>
          </div>
          <div class="BDesign__sum-wrapper">
            <div class="BDesign__sum-title">${lang.summaryTitle}<div class="BDesign__warning-icon">!</div></div>
            <div class="BDesign__sum-main">
              <span class="BDesign__sum-label">${lang.summaryTotal}</span>
              <div class="BDesign__sum-content">
                <div class="BDesign__sum-item">
                  <div class="BDesign__sum-value" backupEnergy></div>
                  <div class="BDesign__sum-desc">${lang.summaryBackupEnergy}</div>
                </div>
                <div class="BDesign__sum-item">
                  <div class="BDesign__sum-value" backupPower></div>
                  <div class="BDesign__sum-desc">${lang.summaryBackupPower}</div>
                </div>
                <div class="BDesign__sum-item">
                  <div class="BDesign__sum-value" surgePower></div>
                  <div class="BDesign__sum-desc">${lang.summarySurgePower}</div>
                </div>
              </div>
            </div>
            <div class="BDesign__sum-btn">
              <div class="step-next__btn step__sum-btn">${lang.ok}</div>
            </div>
          </div>
        </div>
        <div class="BDesign__footer show">
          <div class="BDesign__footer-box">
            <div class="BDesign__footer-label">${lang.contactDirectly}</div>
            <div class="BDesign__footer-main">
              <div class="BDesign__footer-icon">
                <img class="BDesign__footer-icon-img" alt="phone img">
              </div>
              <div class="BDesign__footer-content">
                <p class="BDesign__footer-phone"></p>
                <p class="BDesign__footer-desc"></p>
              </div>
            </div>
          </div>
        </div>
    </div>
  `
  },
  getHomeListTemplate(list=[],lang) {
    let fragment = ''
    list.map((item) => {
      fragment += `
        <div class="BDesign__home-item" data-id="${item.id}">
          <div class="BDesign__home-logo--box">
            <img class="BDesign__home-icon" src="${item.sceneGraph}" alt="home item logo">
            <div class="BDesign__home-item--hidden"><span class="BDesign__home-item--btn">${lang.goNow}</span></div>
          </div>
          <div class="BDesign__home-item-text">
            <p class="BDesign__home-item--title">${item.name}</p>
            <p class="BDesign__home-item--desc">${item.sceneBrief ?? ''}</p>
          </div>
        </div>`
    })
    return fragment
  },
  getStepContainerTemplate(selectData) {
    const data = selectData.typeObj
    const mode = selectData.mode
    const step = selectData.step[mode]
    const stepData = selectData.stepData
    let lang = selectData.lang
    let _selectData = JSON.parse(JSON.stringify(selectData))
    let tabs = `<div class="BDesign__step-tabs">
        <div class="BDesign__step-tab active" data-mode="wizard">${lang.wizard}</div>
        <div class="BDesign__step-tab" data-mode="userDefined">${lang.userDefined}</div>
      </div>`
    !data.custom && (tabs = '')
    let fragment = `
      <h3 class="BDesign__step-title">${data.name}</h3>
      ${tabs}
      <div class="BDesign__step-back"><div class="BDesign__step-back--box"><span class="BDesign__step-back--icon"><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" fill="none" version="1.1" width="5" height="10" viewBox="0 0 5 10"><g transform="matrix(-1,0,0,1,10,0)"><path d="M4.434315,0.565685Q4.3217929999999996,0.453164,4.260896,0.306147Q4.2,0.15913,4.2,0Q4.2,-0.0787931,4.215372,-0.156072Q4.230744,-0.233351,4.260896,-0.306147Q4.291049,-0.378942,4.334824,-0.444456Q4.378599,-0.50997,4.434315,-0.565685Q4.49003,-0.621401,4.555544,-0.665176Q4.621058,-0.708951,4.693853,-0.739104Q4.766649,-0.769256,4.843928,-0.784628Q4.9212069,-0.8,5,-0.8Q5.15913,-0.8,5.306147,-0.739104Q5.453164,-0.678207,5.565685,-0.565685L10.56569,4.43431Q10.67821,4.54684,10.7391,4.69385Q10.8,4.84087,10.8,5Q10.8,5.15913,10.7391,5.30615Q10.67821,5.45316,10.56569,5.56569L5.565685,10.5657Q5.453164,10.6782,5.306147,10.7391Q5.15913,10.8,5,10.8Q4.9212069,10.8,4.843928,10.7846Q4.766649,10.7693,4.693853,10.7391Q4.621058,10.7089,4.555544,10.6652Q4.49003,10.6214,4.434315,10.5657Q4.378599,10.51,4.334824,10.4445Q4.291049,10.3789,4.260896,10.3061Q4.230744,10.2333,4.215372,10.1561Q4.2,10.0788,4.2,10Q4.2,9.84087,4.260896,9.69385Q4.3217929999999996,9.54684,4.434314,9.43431L4.434703,9.43393L8.86863,5L4.434315,0.565685Z" fill-rule="evenodd" fill="#FFFFFF" fill-opacity="1"/></g></svg></span><span class="BDesign__step-back--text">${lang.pre}</span></div></div>
      <div class="BDesign__step-list">
        <div class="BDesign__step-list--box">${this.getStepListTemplate(mode,step,lang).fragment}</div>
        <div class="BDesign__step-label--box">${this.getStepListTemplate(mode,step,lang).labels}</div>
      </div>
      <div class="BDesign__step-wrapper wizard show ${!data.custom ? 'unCustom' : ''}">
        <div class="BDesign__step show" step1>
          <div class="BDesign__step-box">${this.getStepBoxTemplate(Object.assign(_selectData,{mode: 'wizard'}))}</div>
        </div>
        <div class="BDesign__step" step2></div>
        <div class="BDesign__step refer" step3></div>
        <div class="BDesign__step refer" step4></div>
        <div class="BDesign__step refer" step5></div>
      </div>
      <div class="BDesign__step-wrapper userDefined  ${!data.custom ? 'unCustom' : ''}">
        <div class="BDesign__step show" step1>
          <div class="BDesign__step-box">${this.getStepBoxTemplate(Object.assign(_selectData,{mode: 'userDefined'}))}</div>
        </div>
        <div class="BDesign__step" step2></div>
        <div class="BDesign__step refer" step3></div>
        <div class="BDesign__step refer" step4></div>
      </div>
    `
    return fragment
  },
  getStepListTemplate(mode,step,lang) {
    let options = {
      wizard: [
        {
          label: lang.howToUse
        },
        {
          label: lang.requirements
        },
        {
          label: lang.recommendations
        },
        {
          label: lang.downReport
        },
        {
          label: lang.success
        }
      ],
      userDefined: [
        {
          label: lang.requirements
        },
        {
          label: lang.recommendations
        },
        {
          label: lang.downReport
        },
        {
          label: lang.success
        }
      ]
    }
    let result = {
      fragment: '',
      labels: ''
    }
    let fragment = ''
    let labels = ''
    options[mode].forEach((item,index) => {
      let _step = index + 1
      let classList = ''
      if(_step === step) {
        classList = 'in'
      }else if(_step < step) {
        classList = 'active'
      }
      if(index !== options[mode].length - 1) {
        fragment += `
        <div class="step__item ${classList}" data-step="${_step}">
            <span class="step__item-step">${_step}</span>
            <span class="step__item-label">${item.label}</span>
        </div>
      `
      }
      labels += `
        <div class="step__item--label ${classList}" data-step="${_step}">${item.label}</div>
      `
    })
    result.fragment = fragment
    result.labels = labels
    return result
  },
  getStepBoxTemplate(selectData) {
    let mode = selectData.mode
    let step = selectData.step[mode]
    return this[`getStep${step}Template`](selectData)
  },
  getStep1Template(selectData) {
    let data = selectData.typeObj
    let mode = selectData.mode
    let stepData = selectData.stepData
    let lang = selectData.lang
    let currency = selectData.currency
    let homeData = selectData.homeData
    if(!homeData?.subSceneId) {
      stepData.step1.sceneData = selectData.typeObj.subScenes.sort((a,b) => a.order - b.order)[0]
    }
    let fragment = ``
    if(mode === 'wizard') {
      if(data.sceneType !== 3) {
        let sceneList = '<div class="BDesign__scene-list">'
        let subSceneId = homeData?.subSceneId
        data.subScenes.sort((a,b) => a.order - b.order).forEach((item,index) => {
          let activeClass = index === 0 ? 'active' : ''
          if(subSceneId) {
            activeClass = item.id === stepData.step1.sceneData.id ? 'active' : ''
          }
          sceneList += `<div class="BDesign__scene-item ${activeClass}" data-id="${item.id}">${item.name}</div>`
        })
        sceneList += '</div>'
        let item = this.getStep1ItemTemplate(selectData.stepData.step1.sceneData,lang,selectData.homeData)
        fragment = `
          <div class="BDesign__step-item">
            <div class="BDesign__step-item--label">${lang.howToUse}?</div>
            <div class="BDesign__step-item--box">${sceneList}</div>
          </div>
          <div class="BDesign__step-item camping">
            ${item}
          </div>
          <div class="BDesign__step-item">
            <div class="BDesign__step-item--box">
              <div class="BDesign__step-btn--box">
                <span class="BDesign__step1-next step-next__btn">${lang.next}</span>
              </div>
            </div>
          </div>`
      }else {
        fragment = homeStep1Template(data,stepData,lang,currency)
      }
    }else if(mode === 'userDefined') {
      fragment = getUserStep1Template(data,lang)
    }
    return fragment
  },
  getStep2Template(selectData) {
    let data = selectData.typeObj
    let mode = selectData.mode
    let stepData = selectData.stepData
    let fragment = ''
    if(mode === 'wizard') {
      let sceneID = stepData.step1.sceneID
      let type = data.subScenes.filter(item => item.id === sceneID)[0]?.operType
      if(Number(type) === 1) {
        // 选择负载
        fragment = getApplicationTemplate(selectData)
      }else if(Number(type) === 2) {
        //参考负载
        fragment = getReferenceTemplate(selectData)
      }
    }else if(mode === 'userDefined') {
      fragment = this.getRecommendTemplate(selectData)
    }
    return fragment
  },
  getStep3Template(selectData) {
    let fragment = ''
    let mode = selectData.mode
    if(mode === 'wizard') {
      fragment = this.getRecommendTemplate(selectData)
    }else if(mode === 'userDefined') {
      fragment = getSubmitTemplate(selectData)
    }
    return fragment
  },
  getStep4Template(selectData) {
    let mode = selectData.mode
    let fragment = ''
    if(mode === 'wizard') {
      fragment = getSubmitTemplate(selectData)
    }else if(mode === 'userDefined') {
      fragment = this.getFinalTemplate(selectData)
    }
    return fragment
  },
  getStep5Template(selectData) {
    return this.getFinalTemplate(selectData)
  },
  getFinalTemplate(selectData) {
    let lang = selectData.lang
    let fragment = `
      <div class="BDesign__final-icon--box">
        <div class="BDesign__final-icon">
          <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" fill="none" version="1.1" width="32" height="31.796178817749023" viewBox="0 0 32 31.796178817749023"><defs><clipPath id="master_svg0_170_70505/77_01127"><rect x="0" y="0" width="32" height="31.796178817749023" rx="0"/></clipPath></defs><g clip-path="url(#master_svg0_170_70505/77_01127)"><g><path d="M26.7308359375,8.41469375C27.0308359375,8.71282575,27.1993359375,9.11712875,27.1993359375,9.53868875C27.1993359375,9.96024875,27.0308359375,10.36454875,26.7308359375,10.662688750000001C26.7308359375,10.662688750000001,13.9308759375,23.38111875,13.9308759375,23.38111875C13.6308259375,23.67921875,13.2239459375,23.846618749999998,12.7996759375,23.846618749999998C12.3754159375,23.846618749999998,11.968515937500001,23.67921875,11.6684759375,23.38111875C11.6684759375,23.38111875,5.2684749375,17.021918749999998,5.2684749375,17.021918749999998C4.9770219375,16.72206875,4.8157509475,16.32047875,4.8193964219,15.90364875C4.8230418875,15.48679875,4.9913119375,15.08805875,5.2879649375,14.79329875C5.5846179375,14.49852875,5.9859159375,14.33132875,6.4054359375,14.32770875C6.8249559375,14.32407875,7.2291159375,14.48432875,7.530875937499999,14.77392875C7.530875937499999,14.77392875,12.7996759375,20.009218750000002,12.7996759375,20.009218750000002C12.7996759375,20.009218750000002,24.4684359375,8.41469375,24.4684359375,8.41469375C24.7684359375,8.11665075,25.1753359375,7.949218749999998,25.5996359375,7.94921875C26.0239359375,7.949218749999998,26.4308359375,8.11665075,26.7308359375,8.41469375C26.7308359375,8.41469375,26.7308359375,8.41469375,26.7308359375,8.41469375Z" fill-rule="evenodd" fill="#FFFFFF" fill-opacity="1"/></g></g></svg>
        </div>
      </div>
      <p class="BDesign__final-label">${lang.touchSoon}</p>
      <div class="BDesign__final-box">
        <div class="BDesign__final-download">${lang.downReport}</div>
        <div class="BDesign__final-btn">${lang.finished}</div>
      </div>
    `
    return fragment
  },
  getStep1ItemTemplate(sceneData,lang,homeData) {
    let fragment = ''
    let type = sceneData?.isCamping
    let stepItem = {
      label: '',
      content: ''
    }
    if(type !== 'null') {
      let optionList = [1,2,3,4,5,6,lang.moreThan6]
      let optionFragment = ''
      optionList.forEach((item,index) => {
        let value = item
        index === 7 && (value = 'more')
        optionFragment += `<option value="${value}">${item}</option>`
      })
      if(type === 'camping') {
        // 隐藏 camping
        // stepItem = {
        //   label: lang.howManyPeople,
        //   content: `<div class="BDesign__input-box"><select class="BDesign__input" id="step1__people">${optionFragment}</select></div>`
        // }
      }else if(type === 'other') {
        let value = ''
        if(homeData && homeData.howToUse){
          value = homeData.howToUse
        }
        stepItem = {
          label: lang.howUseIt,
          content: `<div class="BDesign__input-box"><input type="text" class="BDesign__input not-center" value="${value}" id="step1__other" placeholder="${lang.enterSomething}"></div>`
        }
      }
    }
    fragment = `
          <div class="BDesign__step-item--label">${stepItem.label}</div>
          <div class="BDesign__step-item--box">${stepItem.content}</div>`
    return fragment
  },
  getRecommendTemplate(selectData){
    let mode = selectData.mode
    let step = selectData.step[mode]
    let sceneType = selectData.typeObj.sceneType
    let lang = selectData.lang
    let currency = selectData.currency
    document.querySelector(`.BDesign__step-wrapper.${mode} .BDesign__step[step${step}]`).classList.add('refer')
    let recommendList = selectData.recommendation[mode]
    selectData.recommendationGroup[mode] = selectData.recommendation[mode][0]
    selectData.choose[mode].product = selectData.typeObj.recommendExpendProBOS.filter(item => item.id === selectData.recommendationGroup[mode].mainID)[0]
    if(selectData.choose[mode].product.selectType && selectData.choose[mode].product.batteryPacks.length){
      //产品允许添加电池包
      let id = selectData.recommendationGroup[mode].batteryId
      let count = selectData.recommendationGroup[mode].batteryCount ?? 0;
      let productCount= selectData.recommendationGroup[mode].mainCount
      if(id) {
        selectData.choose[mode].battery = selectData.choose[mode].product.batteryPacks.filter(item => item.recommendSubId === selectData.recommendationGroup[mode].batteryId)[0]
      }else {
        selectData.choose[mode].battery = selectData.choose[mode].product.batteryPacks.sort((a,b) => a.expendOrder - b.expendOrder)[0]
      }
      if(count < selectData.choose[mode].battery.maximum * productCount) {
        selectData.bonus[mode].battery.count = 1
        selectData.bonus[mode].battery.has = true
      }else {
        selectData.bonus[mode].battery.count = 0
        selectData.bonus[mode].battery.has = false
      }
    }else {
      selectData.choose[mode].battery = {}
      selectData.bonus[mode].battery.has = false
      selectData.bonus[mode].battery.count = 0
    }
    let panels = selectData.choose[mode].product.solarPanels;
    if(panels.length){
      // 产品支持太阳能板
      if(sceneType === 3) {
        // 户储 标准模式下
        if(mode === 'wizard') {
          selectData.choose[mode].panel = panels
          selectData.bonus[mode].panel = {
            has: +selectData.stepData.step1.hes === 2,
            id: panels[0].recommendSubId
          }
        }else {
          selectData.choose[mode].panel = panels
          selectData.bonus[mode].panel = {
            has: false,
            id: null,
            count: 0
          }
        }
      }else {
        let dc = selectData.choose[mode].product.dcInputVoltage
        let fitPanels = panels.filter(item => (dc / item.openCircuitVoltage) >= 1).sort((a,b) => a.expendOrder - b.expendOrder)
        if(fitPanels.length) {
          selectData.choose[mode].panel = fitPanels
          selectData.bonus[mode].panel = {
            has: true,
            id: fitPanels[0].recommendSubId,
            count: 1
          }
        }else {
          selectData.choose[mode].panel = []
          selectData.bonus[mode].panel = {
            has: false,
            id: '',
            count: 0
          }
        }
      }
    }else {
      selectData.choose[mode].panel = []
      selectData.bonus[mode].panel = {
        has: false,
        id: '',
        count: 0
      }
    }
    let recommendFragment = ''
    let screenMode = selectData.screenMode
    let options = []
    recommendList.forEach((item,index) => {
      let viewClass = ''
      item.productLink && (viewClass = 'show')
      let title = lang.system
      let message = item.power + ' W'
      item.productCapacity && (message = `${item.power} W , ${Math.ceil(item.productCapacity * item.mainCount * 100) / 100} Wh`)
      let count = `${item.mainCount} * ${item.productName}`
      let price = ''
      let productPrice = Number(item.productPrice)
      let mainPrice = 0
      let discountPrice = 0
      let productDiscount = 0
      let mainIsDiscount = item.discountCondition && item.mainCount >= item.discountCondition
      let batteryIsDiscount = false
      let batteryPrice = 0
      let batteryDiscount = 0
      if(item.batteryId) {
        // 电池包数量不为0
        title = `${lang.system} + ${lang.expansionBattery}`
        message = `${item.power} W , ${item.capacity} Wh`
        count = `${item.mainCount} * ${item.productName} + ${item.batteryCount} * ${item.batteryName}`
        batteryPrice = item.batteryPrice
        batteryDiscount = item.batteryDiscountPrice ?? 0
        batteryIsDiscount = item.batteryDiscountCondition && item.batteryCount >= item.batteryDiscountCondition
      }
      mainPrice = productPrice + batteryPrice
      price = `<p class="BDesign__recommend-price--box"><span class="BDesign__recommend-unit">${currency} </span><span class="BDesign__recommend-price--value">${mainPrice}</span></p>`
      if(mainIsDiscount || batteryIsDiscount) {
        discountPrice += productPrice + batteryDiscount
        price = `<p class="BDesign__recommend-price--box"><span class="BDesign__recommend-unit">${currency} </span><span class="BDesign__recommend-price--value">${discountPrice}</span></p><span class="BDesign__recommend-discount"><s class="BDesign__recommend-discount--value">${currency} ${mainPrice}</s></span>`
      }
      let _class = ''
      index === 0 && (_class = 'active')
      let view = ''
      item.productLink && (view = `<span class="BDesign__recommend-view ${viewClass}" data-url="${item.productLink}">${lang.viewMore}</span>`)
      let productClass = ''
      if(screenMode === 'phone') {
        options.push({label: count,value: index})
        let width = document.querySelector('.BDesign__container').getBoundingClientRect().width
        if(width <= 765) {
          productClass = 'phone'
        }
      }
      recommendFragment += `
        <div class="BDesign__recommend-item ${_class} ${productClass}" data-index="${index}">
          <div class="BDesign__recommend-item--img">
            <img src="${item.productIcon}" alt="product img">
          </div>
          <div class="BDesign__recommend-item--main">
            <div class="BDesign__recommend-item--title">${count}</div>
            <div class="BDesign__recommend-item--message">${message}</div>
<!--            <div class="BDesign__recommend-item&#45;&#45;count">${count}</div>-->
            <div class="BDesign__recommend-item--price">${item.productDesc ?? ''}</div>
          </div>
          <div class="BDesign__recommend-item--view">${view}</div>
        </div>
      `
    })
    let batteryFragment = this.getBatteryTemplate(selectData)
    let panelFragment = ''
    if(mode === 'wizard' && sceneType === 3 && Number(selectData.stepData.step1.hes) === 1 || (mode === 'userDefined' && sceneType === 3)) {
      panelFragment = ''
    }else {
      panelFragment = this.getPanelTemplate(selectData)
    }
    // 推荐产品展示模式
    let selectFragment = ''
    if(screenMode === 'phone') {
      let optionsFragment = ''
      options.forEach(item => {
        optionsFragment += `<option value="${item.value}" ${item.value === 0 ? 'selected' : ''}>${item.label}</option>`
      })
      selectFragment = `
        <div class="BDesign__recommend-select--box">
          <div class="BDesign__input-box">
            <select class="BDesign__input not-center" id="BDesign__recommend-select">${optionsFragment}</select>
          </div>
        </div>`
    }
    let fragment = `
     <div class="BDesign__recommend-label">${lang.clickSelectRecommendation}</div>
     ${selectFragment}
     <div class="BDesign__recommend-list">${recommendFragment}</div>
     <div class="BDesign__recommend-choose--box battery">${batteryFragment}</div>
     <div class="BDesign__recommend-choose--box panel">${panelFragment}</div>
     <div class="BDesign__step-item--box">
        <div class="BDesign__step-btn--box">
          <span class="BDesign__to-down step-next__btn">${lang.getReport}</span>
        </div>
      </div>
    `
    return fragment
  },
  getBatteryTemplate(selectData) {
    let mode = selectData.mode
    let data = selectData.recommendationGroup[mode];
    let product = selectData.choose[mode].product;
    let battery  = selectData.choose[mode].battery;
    let lang = selectData.lang
    let showBattery = true;
    // 0 不支持电池包 1 支持电池包 2 必须搭配
    let type = Number(product.selectType)
    if(type) {
      if(product.batteryPacks.length) {
        showBattery = (data.batteryCount ? data.batteryCount : 0) < battery.maximum * data.mainCount;
      }else {
        showBattery = false
      }
    }else {
      showBattery = false
    }
    let fragment = '';
    if(showBattery) {
      let screenMode = selectData.screenMode
      let productClass = ''
      if(screenMode === 'phone') {
        let width = document.querySelector('.BDesign__container').getBoundingClientRect().width
        if(width <= 900) {
          productClass = 'phone'
        }
      }
      let count = Number(selectData.bonus[mode].battery.count)
      let maxCount = (Number(battery.maximum) * Number(data.mainCount)) - (data.batteryCount ?? 0)
      let showAdjust = maxCount > 1 ? '' : 'hidden'
      let view = ''
      if(battery.productLink) {
        view = `<span class="BDesign__recommend-view" data-url="${battery.productLink}">${lang.viewMore}</span>`
      }
      fragment = `
          <div class="BDesign__recommend-label">${lang.needExpansionBattery}</div>
          <div class="BDesign__recommend-choose">
            <div class="BDesign__recommend-choose--item choose-item active" data-value="show">${lang.yes}</div>
            <div class="BDesign__recommend-choose--item choose-item" data-value="hidden">${lang.no}</div>
          </div>
          <div class="BDesign__recommend-choose--content show">
            <div class="BDesign__recommend-battery ${productClass}">
              <div class="BDesign__battery-icon">
                <img src="${battery.productIcon}" alt="battery icon">
              </div>
              <div class="BDesign__battery-message">
                <p class="BDesign__battery-title">${lang.expansionBattery}</p>
                <p class="BDesign__battery-capacity">${battery.capacity} Wh</p>
                <p class="BDesign__battery-name">${battery.productName}</p>
                <div class="BDesign__battery-price">${battery.productDesc ?? ''}</div>
              </div>
              <div class="BDesign__battery-count ${showAdjust}">
                <span class=" BDesign__battery-incr"></span>  
                <input type="text" class="BDesign__battery-input" value="${count}">
                <span class="BDesign__battery-add"></span>
              </div>
              <div class="BDesign__battery-view">${view}</div>
            </div>
          </div>
      `
    }
    return fragment
  },
  getPanelTemplate(selectData) {
    let mode = selectData.mode
    let sceneType = selectData.typeObj.sceneType
    let tips = ''
    let data = selectData.recommendationGroup[mode]
    let _panels = selectData.choose[mode].panel
    let dc = selectData.choose[mode].product.dcInputVoltage
    let id = selectData.bonus[mode].panel.id
    let lang = selectData.lang
    if(!id) {
      let fitPanels = _panels.filter(item => (dc / item.openCircuitVoltage) >= 1).sort((a,b) => a.expendOrder - b.expendOrder)
      if(fitPanels.length) {
        selectData.choose[mode].panel = fitPanels
        selectData.bonus[mode].panel = {
          has: true,
          id: fitPanels[0].recommendSubId,
          count: 1
        }
      }else {
        selectData.choose[mode].panel = []
        selectData.bonus[mode].panel = {
          has: false,
          id: '',
          count: 0
        }
      }
    }
    let panels  = selectData.choose[mode].panel
    let fragment = ''
    if(panels.length) {
      let view = ''
      let btnClass = ''
      let showAdjust = ''
      let screenMode = selectData.screenMode
      let productClass = ''
      let panel = panels.filter(item => item.recommendSubId === id)[0]
      let ratedPower = panel.ratedPower
      if(screenMode === 'phone') {
        let width = document.querySelector('.BDesign__container').getBoundingClientRect().width
        if(width <= 900) {
          productClass = 'phone'
        }
      }
      let options = ''
      panels.forEach(item => {
        options += `<option value="${item.recommendSubId}" ${item.recommendSubId === id ? 'selected' : ''}>${item.productName}</option>`
      })
      let count = Number(selectData.bonus[mode].panel.count)
      if(sceneType === 3 && mode === 'wizard') {
        //家庭
        tips = `<p class="BDesign__recommend-tip">${lang.basedBill}</p>`
        showAdjust = 'disabled'
        view = ''
        btnClass = 'disabled'
        let perDay = selectData.stepData.step1.perDay
        count = Math.ceil(perDay * 1000 / panel.ratedPower / 5 / 0.8)
        selectData.bonus[mode].panel.count = count
      }else {
        let dc = Number(data.dcInputVoltage)
        let maxCount = parseInt(dc / panel.openCircuitVoltage)
        showAdjust = maxCount > 1 ? '' : 'hidden'
        panel.productLink && (view = `<span class="BDesign__recommend-view" data-url="${panel.productLink}">${lang.viewMore}</span>`)
      }
      fragment = `
          <div class="BDesign__recommend-label">${lang.needSolarPanel}</div>
          ${tips}
          <div class="BDesign__recommend-choose">
            <div class="BDesign__recommend-choose--item panel-item active" data-value="show">${lang.yes}</div>
            <div class="BDesign__recommend-choose--item panel-item" data-value="hidden">${lang.no}</div>
          </div>
          <div class="BDesign__recommend-choose--content show">
            <div class="BDesign__recommend-battery ${productClass}">
              <div class="BDesign__battery-icon">
                <img src="${panel.productIcon}" alt="battery icon">
              </div>
              <div class="BDesign__battery-message">
                <p class="BDesign__battery-title">${lang.solarPanel}</p>
                <p class="BDesign__battery-capacity">${ratedPower} W</p>
                <p class="BDesign__battery-name">
                  <select class="BDesign__panel-select">${options}</select>
                </p>
                <div class="BDesign__battery-price">${panel.productDesc ?? ''}</div>
              </div>
              <div class="BDesign__battery-count ${showAdjust}">
                <span class=" BDesign__panel-incr ${btnClass}"></span>  
                <input type="text" class="BDesign__panel-input" ${btnClass} value="${count}">
                <span class="BDesign__panel-add ${btnClass}"></span>
              </div>
              <div class="BDesign__battery-view">${view}</div>
            </div>
          </div>
      `
    }
    return fragment
  }
}

function homeStep1Template(data,stepData,lang,currency) {
  let fragment = `
    <div class="BDesign__step-item">
      <div class="BDesign__step-item--label">${lang.enterZipCode}</div>
      <div class="BDesign__step-item--box">
        <div class="BDesign__input-box" prop="zip">
          <input type="text" class="BDesign__input not-center" id="BDesign__input-zip" prop="zip" value="${stepData.step1.zip ? stepData.step1.zip : ''}" placeholder="${lang.zipCodePlaceholder}">
          <p class="BDesign__error-message">${lang.pleaseEnterZipCode}</p>
        </div>
      </div>
    </div>
    <div class="BDesign__step-item">
      <div class="BDesign__step-item--label">${lang.howMuchBill}</div>
      <div class="BDesign__step-item--tips">${lang.howMuchBillTips}</div>
      <div class="BDesign__step-item--box">
        <div class="BDesign__input-box input-suffix input-prefix" prop="bill">
          <input type="text" class="BDesign__input not-center" id="BDesign__input-bill" placeholder="${lang.billPlaceholder}" prop="bill" value="${stepData.step1.bill}">
          <span class="BDesign__input-prefix">${currency}</span>
          <span class="BDesign__input-suffix">${lang.perMonth}</span>
          <p class="BDesign__error-message">${lang.billError}</p>
        </div>
        <div class="BDesign__input-box input-suffix input-prefix input-fee" prop="fee">
          <input type="text" class="BDesign__input not-center" id="BDesign__input-fee" prop="fee" value="${stepData.step1.fee}">
          <span class="BDesign__input-prefix">${currency}</span>
          <span class="BDesign__input-suffix">${lang.perFee}</span>
        </div>
      </div>
    </div>
    <p class="BDesign__bill-label">${lang.consumption}<span class="BDesign__bill-value">${stepData.step1.perDay}kWh</span>${lang.perDay}</p>
    <div class="BDesign__step-item">
      <div class="BDesign__step-item--label">${lang.useHES}</div>
      <div class="BDesign__step-item--box">
        <div class="BDesign__hes-wrapper">
          <div class="BDesign__hes-item ${+stepData.step1.hes === 1 ? 'active' : ''}" data-id="1">${lang.onlyHES}</div>
          <div class="BDesign__hes-item ${+stepData.step1.hes === 2 ? 'active' : ''}" data-id="2">${lang.HESWithPanel}</div>
        </div>
      </div>
    </div>
    <div class="BDesign__step-item">
      <div class="BDesign__step-item--label">${lang.backupHours}</div>
      <div class="BDesign__step-item--box">
        <div class="BDesign__count-box">
          <div class="BDesign__count-decr"></div>
          <div class="BDesign__count-main">
            <input type="text" class="BDesign__input" id="BDesign__input-hour" placeholder="hours" value="${stepData.step1.hour}">
            <span class="BDesign__count-suffix">${lang.hrs}</span>
          </div>
          <div class="BDesign__count-incr"></div>
        </div>
      </div>
    </div>
    <div class="BDesign__step-item">
      <div class="BDesign__step-item--box">
        <div class="BDesign__step-btn--box">
          <span class="BDesign__step1-next step-next__btn">${lang.next}</span>
        </div>
      </div>
    </div>
  `

  return fragment
}
function getUserStep1Template(data,lang) {
  let power = data.power ?? 0
  let capacity = data.capacity ?? 0
  let fragment = `
      <div class="step__title">${lang.capacityTitle}</div>
      <div class="BDesign__step-item">
        <div class="BDesign__step-item--label">${lang.power}</div>
        <div class="BDesign__step-item--box">
          <div class="BDesign__input-box input-suffix">
            <input type="text" class="BDesign__input not-center" id="BDesign__input--power" value="${power}" placeholder="${lang.power}">
            <span class="BDesign__input-suffix">W</span>
            <p class="BDesign__error-message">${lang.pleaseEnterPower}</p>
          </div>
        </div>
      </div>
      <div class="BDesign__step-item">
        <div class="BDesign__step-item--label">${lang.capacity}</div>
        <div class="BDesign__step-item--box">
          <div class="BDesign__input-box input-suffix">
            <input type="text" class="BDesign__input not-center" id="BDesign__input--capacity" value="${capacity}" placeholder="${lang.capacity}">
            <span class="BDesign__input-suffix">Wh</span>
            <p class="BDesign__error-message">${lang.pleaseEnterCapacity}</p>
          </div>
        </div>
      </div>
      <div class="BDesign__step-item">
        <div class="BDesign__step-item--box">
          <div class="BDesign__step-btn--box">
            <span class="BDesign__step1-next step-next__btn">${lang.next}</span>
          </div>
        </div>
      </div>
  `
  return fragment
}
function getReferenceTemplate(selectData) {
  let mode = selectData.mode
  let step = selectData.step[mode]
  let data = selectData.typeObj
  let stepData = selectData.stepData
  let lang = selectData.lang
  document.querySelector(`.BDesign__step-wrapper.${mode} .BDesign__step[step${step}]`).classList.add('refer')
  let sceneID = stepData.step1.sceneID
  let scene = data.subScenes.filter(item => item.id === sceneID)[0]
  let power = scene.power ?? 0
  let capacity = scene.capacity ?? 0
  let referenceList = scene.subLoads
  let referenceFragment = ''
  let unitList = selectData.unitVO
  let powerEnum = selectData.powerEnum
  let dailyEnum = selectData.dailyEnum
  if(referenceList && referenceList.length && referenceList.every(item => item.powerEstimateEquipments.length)) {
    referenceList.forEach(item => {
      if(item.powerEstimateEquipments.length) {
        referenceFragment += `<div class="BDesign__reference-wrapper">`
        referenceFragment += `
        <p class="BDesign__reference-title">${item.name}</p>
        <p class="BDesign__reference-desc">${item.desc}</p>
        <div class="BDesign__reference-box">
          <div class="BDesign__reference-head">
            <div class="BDesign__reference-item reference-name">${lang.equipment}</div>
            <div class="BDesign__reference-item reference-power">${lang.power}</div>
            <div class="BDesign__reference-item reference-usuage">${lang.dailyUsage}</div>
            <div class="BDesign__reference-item reference-energy">${lang.energy}</div>
          </div>
          <div class="BDesign__reference-main">
      `
        item.powerEstimateEquipments.forEach(device => {
          let usageUnit = dailyEnum.filter(unit => unit.id == device.dailyUsageUnit)[0].unit
          let usage = `${device.dailyUsageValue} ${usageUnit}`
          let powerUnit = unitList.filter(unit => unit.key == device.operPowerUnit)[0].value
          let power = `${device.operPower} ${powerUnit}`
          let surgeUnit = powerEnum.filter(unit => unit.id == device.surgePowerUnit)[0].unit
          let surgePower = `${device.surgePowerValue} ${surgeUnit}`
          referenceFragment += `
          <div class="BDesign__reference-line">
            <div class="BDesign__reference-item reference-name">${device.name}</div>
            <div class="BDesign__reference-item reference-power">${power}</div>
            <div class="BDesign__reference-item reference-usuage">${usage}</div>
            <div class="BDesign__reference-item reference-energy">${surgePower}</div>
          </div>
        `
        })
        referenceFragment += '</div></div></div>'
      }
    })
  }
  let fragment = `
    <div class="step__title">${lang.capacityTitle}</div>
    <div class="BDesign__step-box">
      <div class="BDesign__step-item">
        <div class="BDesign__step-item--label">${lang.power}</div>
        <div class="BDesign__step-item--box">
          <div class="BDesign__input-box input-suffix">
            <input type="text" class="BDesign__input not-center" id="BDesign__input--step2-power" value="${power}" placeholder="${lang.power}">
            <span class="BDesign__input-suffix">W</span>
          </div>
        </div>
      </div>
      <div class="BDesign__step-item">
        <div class="BDesign__step-item--label">${lang.capacity}</div>
        <div class="BDesign__step-item--box">
          <div class="BDesign__input-box input-suffix">
            <input type="text" class="BDesign__input not-center" id="BDesign__input--step2-capacity" value="${capacity}" placeholder="${lang.capacity}">
            <span class="BDesign__input-suffix">Wh</span>
          </div>
        </div>
      </div>
    </div>
    ${referenceFragment}
    <div class="BDesign__step-item">
      <div class="BDesign__step-item--box">
        <div class="BDesign__step-btn--box">
          <span class="BDesign__step2-next step-next__btn">${lang.getRecommend}</span>
        </div>
      </div>
    </div>
  `
  return fragment
}
function getApplicationTemplate(selectData) {
  let mode = selectData.mode;
  let step = selectData.step[mode]
  let stepData = selectData.stepData
  let data = selectData.typeObj
  let sum = selectData.calculate
  let lang = selectData.lang
  document.querySelector('.BDesign__sum').classList.add('show')
  document.querySelector(`.BDesign__step-wrapper.${mode} .BDesign__step[step${step}]`).classList.remove('refer')
  selectData.screenMode === 'phone' && document.querySelector(`.BDesign__step-wrapper.${mode} .BDesign__step[step${step}]`).classList.add('refer')
  let sceneID = stepData.step1.sceneID
  let scene = stepData.step1.sceneData
  let power = scene.power
  let capacity = scene.capacity
  let applications = scene.subLoads
  let referenceFragment = ''
  let hasBackup = scene.backup
  let fragment = `<p class="BDesign__application-title">${lang.applicationTitle}</p>`
  applications.sort((a,b) => a.order - b.order).forEach(type => {
    if(!type.isAdditional) {
      //非自定义类型 负载类型
      if(type.powerEstimateEquipments.length) {
        let lines = ''
        type.powerEstimateEquipments.filter(item => item.selected).sort((a,b) => a.order - b.order).forEach(line => {
          const isOwner = line.own ? "checked='checked'" : ''
          let isBackup = ''
          let backupFragment = ''
          if(!hasBackup) {
            line.backup = true
          }else {
            isBackup = line.backup ? "checked='checked'" : ''
            backupFragment = `
          <div class="BDesign__application-item application-backup">
            <input type="checkbox" class="BDesign__switch" ${isBackup}>
          </div>
        `
          }
          let activeClass = line.own ? 'active' : ''
          let operatingPower = line.operPower
          if (Number(line.operPowerUnit) === 2) {
            operatingPower = 1.12 * 1000 * operatingPower
          } else if (Number(line.operPowerUnit) === 3) {
            operatingPower = 0.75 * 1000 * operatingPower
          }
          let perDay =
            Math.ceil((operatingPower * line.dailyUsageValue * line.qty) / 10) / 100
          if (Number(line.dailyUsageUnit) === 2) {
            //电车
            perDay =
              Math.ceil((2.5 * 1000 * line.dailyUsageValue * line.qty) / 10) / 100
          }
          let perDayText = `${perDay} kWh`
          !line.own && (perDayText = `0 kWh`)
          let currentFragment = ''
          selectData.powerEnum.forEach((item) => {
            let select =
              Number(line.surgePowerUnit) === Number(item.id) ? 'selected' : ''
            currentFragment += `<option value="${item.id}" ${select}>${item.name}</option>`
          })
          let unitFragment = ''
          selectData.unitVO.slice(0, 3).forEach((item) => {
            let select =
              Number(line.operPowerUnit) === Number(item.key) ? 'selected' : ''
            unitFragment += `<option value="${item.key}" ${select}>${item.value}</option>`
          })
          line.selected && (lines += `
        <div class="BDesign__application-line" data-id='${line.id}'>
          <div class="BDesign__application-main--left">
            <div class="BDesign__application-tr--box">
              <div class="BDesign__application-item application-name">
                <div class="application-item__svg-box ${activeClass}">
                  <img class="application-item__svg" src="${line.unselectedIcon}" alt="">
                </div>
                <div class="application-item__name-box">
                  <p class="application-item__name">${line.name}</p>
                  <p class="application-item__desc"><span class="application-item__per">${perDayText}</span>${lang.perDay}</p>
                </div>
              </div>
              <div class="BDesign__application-item application-own">
                <input type="checkbox" class="BDesign__checkbox" id="${line.id}" ${isOwner} value="1">
                <label for="${line.id}"></label>
              </div>
              ${backupFragment}
              <div class="BDesign__application-item application-qty">
                <div class="appliance__item-qty-pc">
                  <span class="appliance__qty-incr">−</span>
                  <input type="text" class="appliance__qty-count" value="${line.qty}">
                  <span class="appliance__qty-add">+</span>
                </div>
                <div class="application__item-qty--count">${line.qty}</div>
              </div>
            </div>
            <div class="BDesign__application-tr--hidden">
              <div class="BDesign__hidden-tr">
                <div class="BDesign__hidden-name">${lang.quantity}</div>
                <div class="BDesign__hidden-main">
                  <div class="appliance__item-qty-pc">
                    <span class="appliance__qty-incr">−</span>
                    <input type="text" class="appliance__qty-count" value="${line.qty}">
                    <span class="appliance__qty-add">+</span>
                  </div>
                </div>
              </div>
              <div class="BDesign__hidden-tr">
                <div class="BDesign__hidden-name">${lang.operatingPower}</div>
                <div class="BDesign__hidden-main">
                  <input type="text" class="appliance__operate-input edit-input" value="${line.operPower}">
                  <select class="appliance__operate-select edit-input" value="${line.operPowerUnit}">${unitFragment}</select>
                </div>
              </div>
              <div class="BDesign__hidden-tr">
                <div class="BDesign__hidden-name">${lang.dailyUsage}</div>
                <div class="BDesign__hidden-main">
                  <div class="appliance__edit-input-box">
                    <input type="text" class="appliance__daily-input edit-input" value="${line.dailyUsageValue}">
                    <span class="appliance__daily-suffix">${selectData.dailyEnum.filter((item) => Number(item.id) === Number(line.dailyUsageUnit))[0].unit}</span>
                  </div>
                </div>
              </div>
              <div class="BDesign__hidden-tr">
                <div class="BDesign__hidden-name">
                  <select class="appliance__current-select edit-input" value="${line.surgePowerUnit}">${currentFragment}</select>
                </div>
                <div class="BDesign__hidden-main">
                  <div class="appliance__edit-input-box">
                    <input type="text" class="appliance__current-input edit-input" value="${line.surgePowerValue}">
                    <span class="appliance__current-suffix">${selectData.powerEnum.filter((t) => Number(t.id) === Number(line.surgePowerUnit))[0].unit}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="BDesign__application-main--right">
            <div class="BDesign__application-item application-power">
              <input type="text" class="appliance__operate-input edit-input" value="${line.operPower}">
              <select class="appliance__operate-select edit-input" value="${line.operPowerUnit}">${unitFragment}</select>
            </div>
            <div class="BDesign__application-item application-usage">
              <div class="appliance__edit-input-box">
                <input type="text" class="appliance__daily-input edit-input" value="${line.dailyUsageValue}">
                <span class="appliance__daily-suffix">${selectData.dailyEnum.filter((item) => Number(item.id) === Number(line.dailyUsageUnit))[0].unit}</span>
              </div>
            </div>
            <div class="BDesign__application-item application-surge">
              <select class="appliance__current-select edit-input" value="${line.surgePowerUnit}">${currentFragment}</select>
              <div class="appliance__edit-input-box">
                <input type="text" class="appliance__current-input edit-input" value="${line.surgePowerValue}">
                <span class="appliance__current-suffix">${selectData.powerEnum.filter((t) => Number(t.id) === Number(line.surgePowerUnit))[0].unit}</span>
              </div>
            </div>
          </div>
        </div>
      `)
        })
        let backupHead = ''
        scene.backup && (backupHead = `<div class="BDesign__application-item application-backup">${lang.backup}</div>`)
        fragment += `<div class="BDesign__application-wrapper " data-id="${type.id}">
        <div class="BDesign__application-top">
          <div class="BDesign__application-top--name">
              <div class="BDesign__application-top--name-box">
                <p class="BDesign__application--name">${type.name}</p>
                <p class="BDesign__application--desc">${type.desc}</p>
              </div>
              <span class="BDesign__application-edit"></span>
            </div>
          <div class="BDesign__application-top--total">
              <div class="BDesign__application-total--item">
                <p class="BDesign__application-total--value" backupEnergy>${sum[type.id].backupEnergy} kWh</p>
                <p class="BDesign__application-total--name">${lang.summaryBackupEnergy}</p>
              </div>
              <div class="BDesign__application-total--item">
                <p class="BDesign__application-total--value" backupPower>${sum[type.id].backupPower} kW</p>
                <p class="BDesign__application-total--name">${lang.summaryBackupPower}</p>
              </div>
              <div class="BDesign__application-total--item">
                <p class="BDesign__application-total--value" surgePower>${sum[type.id].surgePower} kW</p>
                <p class="BDesign__application-total--name">${lang.summarySurgePower}</p>
              </div>
            </div>
        </div>
        <div class="BDesign__application-main ${!hasBackup ? 'no-backup' : ''}">
          <div class="BDesign__application-main--head">
            <div class="BDesign__application-main--left">
              <div class="BDesign__application-item application-name"></div>
              <div class="BDesign__application-item application-own">${lang.own}</div>
              ${backupHead}
              <div class="BDesign__application-item application-qty">${lang.qty}</div>
            </div>
            <div class="BDesign__application-main--right">
              <div class="BDesign__application-item application-power">${lang.operatingPower}</div>
              <div class="BDesign__application-item application-usage">${lang.dailyUsage}</div>
              <div class="BDesign__application-item application-surge">${lang.current}</div>
            </div>
            <span class="BDesign__application-edit"></span>
          </div>
          <div class="BDesign__application-main--box">
            ${lines}
          </div>
        </div>
    `
        fragment += '</div>'
      }
    }
  })
  return fragment
}
function getSubmitTemplate(selectData) {
  let mode = selectData.mode
  let productData = selectData.choose[mode].product
  let product = selectData.recommendationGroup[mode]
  let sceneType = selectData.typeObj.sceneType
  let batteryID = product.batteryId
  let hasBattery = product.hasBattery
  let batteryLength = productData.batteryPacks.length
  let hasBonusBattery = selectData.bonus[mode].battery.has
  let hasPanel = selectData.bonus[mode].panel.has
  let mainCount = product.mainCount
  let mainPrice = Math.ceil(product.productPrice * 100) / 100
  let mainCondition = product.discountCondition
  let discountPrice = Math.ceil(product.discountPrice * mainCount * 100) / 100
  let priceSum = 0
  let productPrice = ''
  let lang = selectData.lang
  let currency = selectData.currency
  if(mainPrice !== 0) {
    productPrice = `<p class="BDesign__submit-price--box"><span class="BDesign__price-unit">${currency}</span><span class="BDesign__price-value">${mainPrice}</span></p>`
  }
  if(mainCondition && mainCount >= mainCondition) {
    priceSum += discountPrice
    if(discountPrice !== 0 || mainPrice !== 0) {
      productPrice = `<p class="BDesign__submit-price--box"><span class="BDesign__price-unit">${currency}</span><span class="BDesign__price-value">${discountPrice}</span></p><s class="BDesign__price-discount">${currency} ${mainPrice}</s>`
    }
  }else {
    priceSum += mainPrice
  }
  let productView = ''
  product.productLink && (productView = `<span class="BDesign__submit-view" data-url="${product.productLink}">${lang.viewMore}</span>`)
  let productCapacityFragment = ''
  productCapacityFragment = product.productCapacity ? `<p class="BDesign__submit-product--capacity">${Math.ceil(product.productCapacity * product.mainCount * 100) / 100} Wh</p>` : ''
  let productFragment = `
    <div class="BDesign__submit-product">
      <div class="BDesign__submit-product--icon"><img src="${product.productIcon}" alt="recommend product"></div>
      <div class="BDesign__submit-product--box">
        <p class="BDesign__submit-product--type">${product.mainCount} * ${product.productName}</p>
        <p class="BDesign__submit-product--capacity">${product.power} W</p>
        ${productCapacityFragment}
      </div>
      <div class="BDesign__submit-product--price">${productPrice}</div>
      <div class="BDesign__submit-product--view">${productView}</div>
    </div>
  `
  //电池包
  if((hasBattery && batteryID) || hasBonusBattery) {
    let battery = selectData.choose[mode].battery
    let count = Number(product.batteryCount ?? 0)
    hasBonusBattery && (count += selectData.bonus[mode].battery.count)
    let condition = battery.discountCondition
    let _mainPrice = Math.ceil(count * battery.productPrice * 100 ) / 100
    let discountPrice = Math.ceil(battery.discountPrice * 100 ) / 100
    let price = ''
    if(_mainPrice !== 0) {
      price = `<p class="BDesign__submit-price--box"><span class="BDesign__price-unit">${currency}</span><span class="BDesign__price-value">${_mainPrice}</span></p>`
    }
    if(condition && count >= Number(condition)) {
      priceSum += discountPrice * count
      if(discountPrice !== 0 || _mainPrice !== 0) {
        price = `<p class="BDesign__submit-price--box"><span class="BDesign__price-unit">${currency}</span><span class="BDesign__price-value">${Math.ceil(discountPrice * count * 100) / 100}</span></p><s class="BDesign__price-discount">${currency} ${_mainPrice}</s>`
      }
    }else {
      priceSum += _mainPrice
    }
    let _productView = ''
    battery.productLink && (_productView = `<span class="BDesign__submit-view" data-url="${battery.productLink}">${lang.viewMore}</span>`)
    productFragment += `
    <div class="BDesign__submit-product">
      <div class="BDesign__submit-product--icon"><img src="${battery.productIcon}" alt="recommend product"></div>
      <div class="BDesign__submit-product--box">
        <p class="BDesign__submit-product--type">${lang.expansionBattery}</p>
        <p class="BDesign__submit-product--capacity">${battery.capacity * count} Wh</p>
        <p class="BDesign__submit-product--count">${count} * ${battery.productName}</p>
      </div>
      <div class="BDesign__submit-product--price">${price}</div>
      <div class="BDesign__submit-product--view">${_productView}</div>
    </div>
    `
  }
  //太阳能板
  if(hasPanel) {
    let panelID = selectData.bonus[mode].panel.id
    let panelCount = selectData.bonus[mode].panel.count
    let panel = productData.solarPanels.filter(item => item.recommendSubId === panelID)[0]
    let panelPrice = Math.ceil(panel.productPrice * 100 ) / 100
    let panelCondition = panel.discountCondition
    let panelDiscount = Math.ceil(panel.discountPrice * 100 ) / 100
    let panelPriceFragment = ''
    if(panelPrice !== 0) {
      panelPriceFragment = `<p class="BDesign__submit-price--box"><span class="BDesign__price-unit">${currency}</span><span class="BDesign__price-value">${Math.ceil(panelPrice * panelCount * 100) / 100}</span></p>`
    }
    if(panelCondition && panelCount >= Number(panelCondition)) {
      priceSum += panelDiscount * panelCount
      if(panelDiscount !== 0 || panelPrice !== 0) {
        panelPriceFragment = `<p class="BDesign__submit-price--box"><span class="BDesign__price-unit">${currency}</span><span class="BDesign__price-value">${Math.ceil(panelDiscount * panelCount * 100) / 100}</span></p><s class="BDesign__price-discount">${currency} ${Math.ceil(panelPrice * panelCount * 100) / 100}</s>`
      }
    }else {
      priceSum += panelPrice * panelCount
    }
    let panelView = '';
    (sceneType !== 3 && panel.productLink) && (panelView = `<span class="BDesign__submit-view" data-url="${panel.productLink}">${lang.viewMore}</span>`)
    productFragment += `
      <div class="BDesign__submit-product">
        <div class="BDesign__submit-product--icon"><img src="${panel.productIcon}" alt="recommend product"></div>
        <div class="BDesign__submit-product--box">
          <p class="BDesign__submit-product--type">${lang.solarPanel}</p>
          <p class="BDesign__submit-product--capacity">${panel.ratedPower * panelCount} W</p>
          <p class="BDesign__submit-product--count">${panelCount} * ${panel.productName}</p>
        </div>
        <div class="BDesign__submit-product--price">${panelPriceFragment}</div>
        <div class="BDesign__submit-product--view">${panelView}</div>
      </div>
    `
  }
  let totalPrice = ''
  if(priceSum !== 0 && selectData.channel !== 'BS') {
    totalPrice = `
      <div class="BDesign__submit-total">
        <span class="BDesign__submit-total--desc">${lang.summaryTotal}:</span>
        <span class="BDesign__submit-total--unit">${currency}</span>
        <span class="BDesign__submit-total--value">${Math.ceil(priceSum * 100) / 100}</span>
      </div>
      <div class="BDesign__submit-product--tips">${lang.priceOnly}</div>
    `
  }
  let submitFragment = ''
  let tipsFragment = ''
  if(sceneType === 3 || selectData.showSubmit) {
    let homeData = selectData.homeData
    let name = homeData.name ? homeData.name : ''
    let email = homeData.email ? homeData.email : ''
    let phone = homeData.phoneNumber ? homeData.phoneNumber : ''
    let address = homeData.address ? homeData.address : ''
    let agreeFragment = `
        <p class="BDesign__agree-tips">${lang.pleaseAgree}</p>
        <div class="BDesign__submit-agree">
          <input type="checkbox" id="BDesign__${mode}-agree" class="BDesign__agree">
          <label for="BDesign__${mode}-agree">${lang.agreeDesc}</label>
        </div>
    `
    if(selectData.channel === 'BS') {
      agreeFragment = ''
    }
    submitFragment = `
    <div class="BDesign__submit-contact">
      <div class="BDesign__submit-contact--box">
        <div class="BDesign__submit-contact--title">${lang.contactTitle}</div>
        <div class="BDesign__submit-contact--line">
          <div class="BDesign__submit-contact--item">
            <div class="BDesign__input-box" prop="name">
              <input type="text" class="BDesign__input not-center" maxlength="100" value="${name}" id="BDesign__input-name" prop="name" placeholder="${lang.pleaseEnterYourName}">
              <p class="BDesign__error-message">${lang.pleaseEnterYourName}</p>
            </div>
          </div>
          <div class="BDesign__submit-contact--item">
            <div class="BDesign__input-box" prop="email">
              <input type="text" class="BDesign__input not-center" maxlength="100" value="${email}" id="BDesign__input-email" prop="email" placeholder="${lang.pleaseEnterYourEmail}">
              <p class="BDesign__error-message"></p>
            </div>
          </div>
        </div>
        <div class="BDesign__submit-contact--line">
          <div class="BDesign__submit-contact--item">
            <div class="BDesign__input-box" prop="phone">
              <input type="text" class="BDesign__input not-center" maxlength="32" value="${phone}" id="BDesign__input-phone" prop="phone" placeholder="${lang.pleaseEnterYourPhone}">
            </div>
          </div>
          <div class="BDesign__submit-contact--item">
            <div class="BDesign__input-box" prop="code">
              <input type="text" class="BDesign__input not-center" maxlength="200" value="${address}" id="BDesign__input-code" prop="code" placeholder="${lang.pleaseEnterYourCode}">
            </div>
          </div>
        </div>
        <div class="BDesign__submit-contact--btn">
          <div class="BDesign__submit-contact--submit">${lang.submit}</div>
        </div>
        ${agreeFragment}
      </div>
    </div>
  `
    tipsFragment = `
      <div class="BDesign__submit-tip">
        <p class="BDesign__submit-tip--line">${lang.submitTitle}</p>
        <p class="BDesign__submit-tip--line">${lang.submitDesc}</p>
      </div>
    `
  }
  const isApp = (selectData.isAPP === 'app' && selectData.type === 'offline');
  const loginHtml = isApp ? `<span class="BDesign__submit-title--download BDesign__submit-login">${lang.login}</span>` : '';
  let fragment = `
    ${tipsFragment}
    <div class="BDesign__submit-title">
      <span class="BDesign__submit-title--label">${lang.downloadReport}</span>
      <span class="BDesign__submit-title--download">${lang.download}</span>
      ${loginHtml}
    </div>
    <div class="BDesign__submit-list">${productFragment}</div>
    <div class="BDesign__submit-recommend">${totalPrice}</div>
    ${submitFragment}
  `

  return fragment
}

