import limit from '../limit'
export default {
  bill(val,price) {
    const days = 30
    return Math.ceil((val / days / price) * 100) / 100
  },
  operatePower(power,lastUnit,unit) {
    //{"key": 1, "value": "W"}, {"key": 2, "value": "ton"}, {"key": 3, "value": "hp"}
    //1Ton = 1.12kW 1120w 1w = 0.00089Ton
    //1hp = 0.75kW 750w 1w = 0.00133hp
    let lastPower = 0
    switch (Number(lastUnit)) {
      case 1: lastPower = power;break;
      case 2: lastPower = power * 1.12 * 1000;break;
      case 3: lastPower = power * 0.75 * 1000;break;
      default: lastPower = power;break;
    }
    let result = 0
    switch (Number(unit)) {
      case 1: result = lastPower;break;
      case 2: result = Math.ceil(lastPower * 0.00089 * 100) / 100; break;
      case 3: result = Math.ceil(lastPower * 0.00133 * 100) / 100; break;
      default: result = lastPower;break;
    }
    return limit.number(result + '')
  },
  surge(power,unit) {
    //{"id": 2, "name": "LRA", "unit": "A"}, {"id": 4, "name": "Surge Power", "unit": "W"}
    //求 W；W = (value * 0.048) * 1000
    //求 A；A = (value / 1000) / 0.048
    let result = 0
    switch (Number(unit)) {
      case 2: result = (power / 1000) / 0.048; break;
      case 4: result = (power * 0.048) * 1000; break;
      default: result = (power * 0.048) * 1000; break;
    }
    return limit.number(Math.ceil(result * 100) / 100 + '')
  },
  //轻型电器
  essential(data,hour) {
    let result = {
      backupEnergy: 0,
      backupPower: 0,
      surgePower: 0,
      load: 0
    }
    data = data.filter(item => item.own)
    result = data.reduce((t, v) => {
      if (v.backup) {
        let operatingPower = v.operPower
        if (Number(v.operPowerUnit) === 2) {
          operatingPower = 1.12 * 1000 * operatingPower
        } else if (Number(v.operPowerUnit) === 3) {
          operatingPower = 0.75 * 1000 * operatingPower
        }
        t.backupEnergy += v.dailyUsageValue * operatingPower * v.qty
        t.backupPower += operatingPower * v.qty
      }
      return t
    }, result)
    result.backupEnergy = Math.ceil(((result.backupEnergy * hour) / 24 / 1000) * 100) / 100
    result.backupPower = Math.ceil(result.backupPower / 10) / 100
    let max = { value: 0, index: 0 }
    data.forEach((item, index) => {
      if (item.backup) {
        let lra = item.surgePowerValue
        if (Number(item.surgePowerUnit) === 2) {
          lra = 0.048 * lra * 1000
        }
        if (lra > max.value) {
          max.value = lra
          max.index = index
        }
      }
    })
    data.forEach((item, index) => {
      let operatingPower = item.operPower
      if (Number(item.operPowerUnit) === 2) {
        operatingPower = 1.12 * 1000 * operatingPower
      } else if (Number(item.operPowerUnit) === 3) {
        operatingPower = 0.75 * 1000 * operatingPower
      }
      if (item.backup) {
        if (index === max.index) {
          let lra = item.surgePowerValue
          if (Number(item.surgePowerUnit) === 2) {
            lra = 0.048 * lra * 1000
          }
          result.surgePower += lra
          result.surgePower += (item.qty - 1) * operatingPower
        } else {
          result.surgePower += operatingPower * item.qty
        }
      }
      if (item.own) {
        result.load += operatingPower * item.qty * item.dailyUsageValue
      }
    })
    result.surgePower = Math.ceil(result.surgePower / 10) / 100
    return result
  },
  // 大型电器
  large(data,hour) {
    let result = {
      backupEnergy: 0,
      backupPower: 0,
      surgePower: 0,
      load: 0
    }
    data = data.filter(item => item.own)
    result = data.reduce((t, v) => {
      if (v.backup) {
        let operatingPower = v.operPower
        if (Number(v.operPowerUnit) === 2) {
          operatingPower = 1.12 * 1000 * operatingPower
        } else if (Number(v.operPowerUnit) === 3) {
          operatingPower = 0.75 * 1000 * operatingPower
        }
        t.backupEnergy += v.dailyUsageValue * operatingPower * v.qty
      }
      return t
    }, result)
    result.backupEnergy = Math.ceil(((result.backupEnergy * hour) / 24 / 1000) * 100) / 100
    const maxList = data
      .filter((item) => item.backup)
      .map((item) => {
        let operatingPower = item.operPower
        if (Number(item.operPowerUnit) === 2) {
          operatingPower = 1.12 * 1000 * operatingPower
        } else if (Number(item.operPowerUnit) === 3) {
          operatingPower = 0.75 * 1000 * operatingPower
        }
        return operatingPower * item.qty
      })
    if (maxList.length) {
      result.backupPower = Math.ceil(Math.max(...maxList) / 10) / 100
    } else {
      result.backupPower = 0
    }
    const maxList2 = data
      .filter((item) => item.backup)
      .map((item) => {
        let lra = item.surgePowerValue
        if (Number(item.surgePowerUnit) === 2) {
          lra = 0.048 * lra * 1000
        }
        let operatingPower = item.operPower
        if (Number(item.operPowerUnit) === 2) {
          operatingPower = 1.12 * 1000 * operatingPower
        } else if (Number(item.operPowerUnit) === 3) {
          operatingPower = 0.75 * 1000 * operatingPower
        }
        return lra + (item.qty - 1) * operatingPower
      })
    if (maxList2.length) {
      result.surgePower = Math.ceil(Math.max(...maxList2) / 10) / 100
    } else {
      result.surgePower = 0
    }
    data.forEach((item) => {
      if (item.own) {
        let operatingPower = item.operPower
        if (Number(item.operPowerUnit) === 2) {
          operatingPower = 1.12 * 1000 * operatingPower
        } else if (Number(item.operPowerUnit) === 3) {
          operatingPower = 0.75 * 1000 * operatingPower
        }
        result.load += operatingPower * item.qty * item.dailyUsageValue
      }
    })
    return result
  },
  // 重型电器
  heavy(data,hour) {
    let result = {
      backupEnergy: 0,
      backupPower: 0,
      surgePower: 0,
      load: 0
    }
    data = data.filter(item => item.own)
    result = data.reduce((t, v) => {
      if (v.backup && Number(v.dailyUsageUnit) !== 2) {
        let operatingPower = v.operPower
        if (Number(v.operPowerUnit) === 2) {
          operatingPower = 1.12 * 1000 * operatingPower
        } else if (Number(v.operPowerUnit) === 3) {
          operatingPower = 0.75 * 1000 * operatingPower
        }
        t.backupEnergy += operatingPower * v.qty * v.dailyUsageValue
      }
      return t
    }, result)

    result.backupEnergy = result.backupEnergy * (hour / 24)
    let car = data.filter((item) => item.backup && Number(item.dailyUsageUnit) === 2)
    car.length && (result.backupEnergy += car[0].qty * car[0].dailyUsageValue * 2.5 * 1000)
    result.backupEnergy = Math.ceil(result.backupEnergy / 10) / 100
    const maxList = data
      .filter((item) => item.backup)
      .map((item) => {
        let operatingPower = item.operPower
        if (Number(item.operPowerUnit) === 2) {
          operatingPower = 1.12 * 1000 * operatingPower
        } else if (Number(item.operPowerUnit) === 3) {
          operatingPower = 0.75 * 1000 * operatingPower
        }
        return Number(operatingPower) * Number(item.qty)
      })
    if (maxList.length) {
      result.backupPower = Math.ceil(Math.max(...maxList) / 10) / 100
    } else {
      result.backupPower = 0
    }
    const maxList2 = data
      .filter((item) => item.backup)
      .map((item) => {
        let lra = item.surgePowerValue
        if (Number(item.surgePowerUnit) === 2) {
          lra = 0.048 * lra * 1000
        }
        let operatingPower = item.operPower
        if (Number(item.operPowerUnit) === 2) {
          operatingPower = 1.12 * 1000 * operatingPower
        } else if (Number(item.operPowerUnit) === 3) {
          operatingPower = 0.75 * 1000 * operatingPower
        }
        return lra + (item.qty - 1) * Number(operatingPower)
      })
    if (maxList2.length) {
      result.surgePower = Math.ceil(Math.max(...maxList2) / 10) / 100
    } else {
      result.surgePower = 0
    }
    data.forEach((item) => {
      if (item.own) {
        let operatingPower = item.operPower
        if (Number(item.operPowerUnit) === 2) {
          operatingPower = 1.12 * 1000 * operatingPower
        } else if (Number(item.operPowerUnit) === 3) {
          operatingPower = 0.75 * 1000 * operatingPower
        }
        if (Number(item.dailyUsageUnit) === 2) {
          result.load += 2.5 * 1000 * item.qty * item.dailyUsageValue
        } else {
          result.load += operatingPower * item.qty * item.dailyUsageValue
        }
      }
    })
    return result
  },
  //自定义电器
  additional(data) {
    let result = {
      backupEnergy: 0,
      backupPower: 0,
      surgePower: 0,
      load: 0
    }
    return result
  },
  // 计算总数
  sum(_data) {
    let result = {}
    let props = ['backupEnergy','backupPower','surgePower','load']
    let keys = Object.keys(_data)
    let data = {
      backupEnergy: [],
      backupPower: [],
      surgePower: [],
      load: []
    }
    keys.forEach(key => {
      let item = _data[key]
      props.forEach(prop => {
        data[prop].push(item[prop])
      })
    })
    result.backupEnergy = Math.ceil(Object.values(data.backupEnergy).reduce((t, v) => (t += v), 0) * 100) / 100
    result.backupPower = Object.values(data.backupPower).reduce((t, v) => (t > v ? t : v),0)
    result.surgePower = Object.values(data.surgePower).reduce((t, v) => (t > v ? t : v),0)
    result.load = Math.ceil(Object.values(data.load).reduce((t,v) => (t+=v,t),0) / 10) / 100
    return result
  },
  //获取推荐组合
  getRecommend(data,selectData) {
    let result = {}
    let max = {}
    //产品拆分
    data = data ? data : []
    data.forEach(item => {
      let group = []
      //主产品最多个数
      let count = item.parallelNum
      // 0 不支持电池包 1 支持电池包 2 必须添加电池包
      let hasBattery = Number(item.selectType ?? 0)
      let _loop = function _loop(i) {
        let _group = {
          mainCount: i,
          mainID: item.id,
          order: i * 10000 + Number(item.order),
          power: item.ratedPower * i,
          capacity: item.capacity * i,
          productCapacity: item.capacity,
          surgePower: item.peakPower * i,
          productPrice: item.productPrice * i,
          productName: item.productName,
          productLink: item.productLink,
          productIcon: item.productIcon,
          productDesc: item.productDesc,
          dcInputVoltage: item.dcInputVoltage,
          discountCondition: item.discountCondition,
          openCircuitVoltage: item.openCircuitVoltage,
          discountPrice: item.discountPrice,
          discountType: item.discountType,
          hasBattery: item.selectType
        }
        if(hasBattery !== 0) {
          hasBattery === 1 && group.push(_group)
          item.batteryPacks.forEach(battery => {
            let max = battery.maximum * i
            let min = battery.minimum * i
            for(let j = min; j <= max; j ++) {
              let temp = JSON.parse(JSON.stringify(_group))
              temp.order += j * 100 + Number(battery.expendOrder)
              temp.capacity += j * battery.capacity
              Object.assign(temp,{
                batteryCount: j,
                batteryCapacity: battery.capacity,
                batteryId: battery.recommendSubId,
                batteryName: battery.productName,
                batteryDesc: battery.productDesc,
                batteryPrice: battery.productPrice * j,
                batteryLink: battery.productLink,
                batteryIcon: battery.productIcon,
                batteryDiscountPrice: battery.discountPrice,
                batteryDiscountCondition: battery.discountCondition,
                batteryDiscountType: battery.discountType,
                max: battery.maximum,
                min: battery.minimum
              })
              group.push(temp)
            }
          })
        }else {
          group.push(_group)
        }
      }
      for(let i = 1;i <= count;i++) {
        (function(i) {_loop(i)})(i)
      }
      result[item.id] = {
        group: group,
        batteryPacks: item.batteryPacks,
        panel: item.solarPanels
      }
    })
    max = getMax(JSON.parse(JSON.stringify(result)))
    let mode = selectData.mode
    let step = selectData.step[mode]
    let stepData = selectData.stepData.step2
    let target = {}
    if(mode === 'userDefined') {
      target = {
        capacity: selectData.stepData.step1.capacity,
        power: selectData.stepData.step1.power
      }
    }else if(mode === 'wizard') {
      let scene = selectData.stepData.step1.sceneData
      if(Number(scene.operType) === 2) {
        //参考负载
        target = {
          capacity: stepData.capacity,
          power: stepData.power
        }
      }else if(scene.operType === 1) {
        //选择负载
        target = {
          capacity: selectData.sum.backupEnergy * 1000,
          power: selectData.sum.backupPower * 1000,
          surgePower: selectData.sum.surgePower * 1000
        }
      }
    }
    result = getBest(result,target)
    return {
      max: max,
      list: result
    }
  },
}
//获取最优推荐
function getBest(data,target) {
  let result = {};
  let keys = Object.keys(data)
  keys.forEach(key => {
    result[key] = []
    data[key].group.sort((a,b) => a.order - b.order).forEach(item => {
      let conform = item.capacity >= target.capacity && item.power >= target.power
      target.surgePower && (conform = conform && item.surgePower >= target.surgePower)
      if(conform) {
        item.diffrence = item.capacity - target.capacity
        result[key].push(item)
      }
    })
  })
  let _keys = Object.keys(result)
  _keys.forEach(key => {
    result[key].sort((a,b) => a.diffrence - b.diffrence)
  })
  let temp = []
  _keys.forEach(key => {
    result[key][0] && temp.push(result[key][0])
  })
  temp.sort((a,b) =>  a.diffrence - b.diffrence)
  let _result = temp.slice(0,2).sort((a,b) => a.order - b.order)
  return _result
}
// 获取推荐最大值
function getMax(data) {
  let result = {
    backupEnergy: 0,
    surgePower: 0,
  }
  let groupList = []
  Object.keys(data).forEach(item => {
    groupList.push(...data[item].group)
  })
  result = groupList.reduce((t,v) => {
    let capacity = Math.ceil((v.capacity ?? 0) / 10) / 100
    let surgePower = Math.ceil((v.surgePower ?? 0) / 10) / 100
    return {
      backupEnergy: t.backupEnergy > capacity ? t.backupEnergy : capacity,
      surgePower: t.surgePower > surgePower ? t.surgePower : surgePower
    }
  }, {
    backupEnergy: 0,
    surgePower: 0,
  })
  return result
}
