import './styles/index.scss'
import render from './utils/getTemplate'
import api from './utils/api'
import limit from './utils/limit'
import calculate from './utils/calculate'

class BluettiDesign {
  #options = {
    el: '',
    mode: '',
    lang: 'us',
    // 区分是否无网模式: offline
    type: '',
    rootURL: 'https://gw.bluettipower.com/api/bluuc',
    jsonURL: 'https://h5.bluettipower.com/webjars/plugins/bluetti-components/bluetti-design/2.0/lang/',
    DEV_ROOT: 'https://dev-gw.poweroak.ltd:18443/api/bluuc',
    // DEV_ROOT: 'http://192.168.40.163:20101',
    PRODUCT_ROOT: 'https://gw.bluettipower.com/api/bluuc',
    TEST_ROOT: 'https://test-gw.poweroak.ltd:18443/api/bluuc',
    PRODUCT_JSON_API: "https://h5.bluettipower.com/webjars/plugins/bluetti-components/bluetti-design/2.0/lang/",
    // DEV_JSON_API: "/dist/lang/",
    DEV_JSON_API: "https://test-app-h5.poweroak.ltd:18443/bluettiDesign2.0/dist/lang/",
  }
  #data = {
    homeList: [],
    unitList: [],
    selectList: [],
    contact: []
  }
  #select = {
    showSubmit: false,
    id: '',
    typeID: '',
    typeObj: {},
    lang: {},
    currency: "USD",
    stepData: {
      step1: {},
      step2: {},
      step3: {},
      step4: {}
    },
    homeData: {},
    mode: 'wizard',
    screenMode: 'pc',
    isAPP: '',
    step: {
      wizard: 0,
      userDefined: 0
    },
    calculate: {},
    sum: {
      backupEnergy: 0,
      backupPower: 0,
      surgePower: 0
    },
    choose: {
      wizard: {
        product: {},
        battery: {},
        panel: {}
      },
      userDefined: {
        product: {},
        battery: {},
        panel: {}
      }
    },
    bonus: {
      wizard: {
        battery: {
          has: false,
          count: 1
        },
        panel: {
          has: false,
          count: 1,
          id: ''
        }
      },
      userDefined: {
        battery: {
          has: false,
          count: 1
        },
        panel: {
          has: false,
          count: 1,
          id: ''
        }
      }
    },
    user: {
      agree: false,
      name: '',
      email: '',
      zip: '',
      phone: ''
    },
    recommendation: {
      wizard: [],
      userDefined: {}
    },
    recommendationGroup: {
      wizard: [],
      userDefined: []
    },
    recommendationMax: {
      wizard: {},
      userDefined: {}
    }
  }
  #countryList = [
    {
      name: 'us',
      currency: 'USD',
    },
    {
      //德国
      name: 'de',
      currency: 'EUR'
    },
    {
      //意大利
      name: "it",
      currency: 'EUR',
    },
    {
      //法国
      name: "fr",
      currency: "EUR",
    },
    {
      //西班牙
      name: "es",
      currency: "EUR"
    },
  ]
  #dataLimit= {
    bill: {
      default: 160,
      min: 50,
      max: 1000
    },
    hour: {
      default: 16,
      max: 40
    },
    fee: {
      default: 0.18
    }
  }
  //导出参数
  #downloadParams = {}
  set step(val) {
    this.#select.step[this.#select.mode] = val
    let _step = this.#select.step[this.#select.mode]
    if(_step !== 0) {
      setTimeout(() => {
        let step = _step
        let max = 5
        this.#select.mode === 'userDefined' && (max = 4)
        if(step === 1) {
          this.find(".step__item[data-step='2']").classList = 'step__item'
          this.find(".step__item[data-step='1']").classList = 'step__item in'
          this.find(".step__item--label[data-step='2']").classList = 'step__item--label'
          this.find(".step__item--label[data-step='1']").classList = 'step__item--label in'
        }else if(step > 1 && step < max) {
          if(step !== max - 1) {
            this.find(`.step__item[data-step='${step + 1}']`).classList = 'step__item'
          }
          this.find(`.step__item[data-step='${step}']`).classList = 'step__item in'
          this.find(`.step__item[data-step='${step - 1}']`).classList = 'step__item active'
          this.find(`.step__item--label[data-step='${step}']`).classList = 'step__item--label in'
          this.find(`.step__item--label[data-step='${step - 1}']`).classList = 'step__item--label'
          this.find(`.step__item--label[data-step='${step + 1}']`).classList = 'step__item--label'
        }else {
          this.find(`.step__item[data-step='${step - 1}']`).classList = 'step__item active'
          this.find(`.step__item--label[data-step='${step - 1}']`).classList = 'step__item--label'
          this.find(`.step__item--label[data-step='${step}']`).classList = 'step__item--label in'
        }
        this.find('.BDesign__wrapper').scrollTop = 0
      },100)
    }else {
      this.clearData()
      this.init()
    }
  }
  get step() {
    return this.#select.step[this.#select.mode]
  }
  constructor(options) {
    if (!options.el)
      throw new Error(
        'Power Estimate Calculator need to specify container. el cannot be empty'
      )
    this.#downloadParams = Object.assign({},options.downloadParams)
    Object.assign(this.#options,{
      el:document.querySelector(options.el),
      mode: options.mode,
      lang: options.lang ?? 'us',
    })
    options.channel && (this.#options.channel = options.channel)
    this.#select.channel = options.channel
    if(options.channel && options.channel.toLowerCase() === 'bs') {
      this.#select.showSubmit = true
    }
    options.siteId && (this.#options.siteId = options.siteId)
    options.uid && (this.#options.franchiserUid = options.uid)
    // type：offline 无网模式
    options.type && (this.#options.type = options.type)
    if (options.type) {
      this.#select.type = options.type;
    }
    if(options.id) {
      this.#options.id = options.id
      this.#select.id = options.id
    }
    switch (this.#options.mode) {
      case 'dev':
        this.#options.rootURL = this.#options.DEV_ROOT
        this.#options.jsonURL = this.#options.DEV_JSON_API + `${this.#options.lang}.json`
        break
      case 'test':
        this.#options.rootURL = this.#options.TEST_ROOT
        this.#options.jsonURL = this.#options.DEV_JSON_API + `${this.#options.lang}.json`
        break
      case 'prod':
        this.#options.rootURL = this.#options.PRODUCT_ROOT
        this.#options.jsonURL = this.#options.PRODUCT_JSON_API + `${this.#options.lang}.json`
        break
      default:
        this.#options.rootURL = this.#options.PRODUCT_ROOT
        this.#options.jsonURL = this.#options.PRODUCT_JSON_API + `${this.#options.lang}.json`
        break
    }
    this.init()
  }
  init() {
    if(this.#options.lang) {
      let _lang = this.#countryList.filter(item => item.name === this.#options.lang.toLowerCase())
      if(_lang.length) {
        this.#options.lang = _lang[0].name
        this.#select.currency = _lang[0].currency
      }
    }
    this.getLangJSON()
  }
  showLoading() {
    this.find('.BDesign__loading').classList.add('show')
  }
  closeLoading() {
    this.find('.BDesign__loading').classList.remove('show')
  }
  // 检查浏览器宽度 是否为移动端
  checkContainer() {
    function getQueryString(name) {
      let reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i')
      let r = window.location.search.substr(1).match(reg)
      if (r != null) {
        return decodeURIComponent(r[2])
      }
      return null
    }
    let agent = getQueryString('agent')
    this.#select.isAPP = ''
    agent === 'app' && (this.#select.isAPP = 'app')
    let width = this.#options.el.getBoundingClientRect().width
    if(width > 1300) {
      this.#select.screenMode = 'pc'
    }else if (width > 1023 && width <= 1300) {
      this.#select.screenMode = 'pad'
    } else if (width > 765 && width <= 1023) {
      this.#select.screenMode = 'phone'
    } else if (width <= 765) {
      this.#select.screenMode = 'phone'
    }
  }
  getLangJSON() {
    const that = this
    let lang = this.#options.lang
    let xhr = new XMLHttpRequest()
    xhr.open('get', this.#options.jsonURL)
    xhr.onload = function(e) {
      if(this.status === 200) {
        that.#select.lang = JSON.parse(this.response)
        that.render(that.#select)
        that.getHomeData()
      }
    }
    xhr.send()
  }
  render() {
    this.checkContainer()
    this.#options.el.innerHTML = render.getContainerTemplate(this.#select)
    let screenMode= this.#select.screenMode
    if(screenMode === 'pad') {
      this.find('.BDesign__home-list').classList.add('pad')
    }else if(screenMode === 'phone') {
      let width = document.querySelector('.BDesign__container').getBoundingClientRect().width
      if(width > 560) {
        this.find(".BDesign__home-list").classList.add('pad')
      }
      if(width > 600) {
        this.find(".BDesign__footer").classList.add("pad")
      }else if(width < 440) {
        this.find(".BDesign__footer").classList.add("phone")
      }
    }
    this.eventBind()
  }
  find(selector){
    return this.#options.el.querySelector(selector)
  }
  findAll(selector) {
    return this.#options.el.querySelectorAll(selector)
  }
  getHomeData() {
    const that = this
    const path = `/power/estimateScene/powerEstimateScenes?gwcredentials=${encodeURIComponent('FMdVvlwyIjcuYX4Z7BR1kie8MYLgZGl6pi9owyTc5RxFlM2vk6W+nC0GcwO0lIFoqqJEigCleFsTwGW8Hr82XdtRcblclQ1G')}`
    const siteIdParams = this.#options.siteId ? `&siteId=${this.#options.siteId}` : ''
    let idStr = this.#options.id ? `&userRecordId=${this.#select.id}` : ''
    let url = this.#options.rootURL + `${path}&countryCode=${this.#options.lang}${idStr}${siteIdParams}`
    let xhr = api.getHomeList(url)
    this.showLoading()
    xhr.onload = function(e) {
      that.closeLoading()
      if(this.status === 200) {
        const res = JSON.parse(this.response)
        if(res.msgCode === 0) {
          that.setHomeData(res.data)
        }else {
          alert(res.message)
        }
      }
    }
    xhr.onerror = function(e) {
      that.closeLoading()
      throw new Error(e.stausText)
    }
    xhr.send()
  }
  setHomeData(data) {
    this.#data.homeList = data.estimateSceneVOS.sort((a,b) => a.order - b.order)
    this.#data.homeList.forEach(item => {
      item.subScenes.sort((a,b) => a.order - b.order)
    })
    this.#data.contact = data.hesConfig
    this.#select.unitVO = data.powerUnitEnum
    this.#select.powerEnum = data.appliancesPowerEnum
    this.#select.dailyEnum = data.dailyUsageEnum
    this.#select.bg = data.hesConfig
    data.powerEstimateUserRecord && (this.#select.homeData = data.powerEstimateUserRecord)
    this.find('.BDesign__home-list').innerHTML = render.getHomeListTemplate(this.#data.homeList,this.#select.lang)
    let container = this.find('.BDesign__container')
    let message = this.#select.bg.filter(item => item.type === 2)[0]
    let logo = this.#select.bg.filter(item => item.type === 1)[0]
    this.find(".BDesign__home-logo img").src = logo ? logo.url: ''
    if(message && message.phone && message.desc) {
      container.classList.remove('no-footer')
      this.find('.BDesign__footer-phone').innerHTML = message.phone
      this.find('.BDesign__footer-desc').innerHTML = message.desc
      this.find('.BDesign__footer-icon-img').src = message.url
    }else {
      container.classList.add('no-footer')
    }
  }
  showSum() {
    //判断是否为选择负载界面  是否展示总数
    let result = false
    let mode = this.#select.mode
    let step = this.#select.step[mode]
    if(mode === 'userDefined') {
      result = false
    }else if(step === 2) {
      let sceneType = this.#select.typeObj.sceneType
      let operType = this.#select.stepData.step1.sceneData.operType
      if(sceneType === 3) {
        //当前为户储第二步
        result = true
      }else result = operType === 1;
    }else {
      result = false
    }
    let sum = this.find(".BDesign__sum")
    if(result) {
      sum.classList.add('show')
    }else{
      sum.classList.remove("show")
    }
  }
  saveData(cb) {
    const that = this
    let url = this.#options.rootURL + `/power/estimateUserRecord/saveUserRecord?gwcredentials=${encodeURIComponent('mOg7w15h2iWWTtLi0cb18NSxUdDmSOawSsWUuTpHZHLD+kj1RTS/tE8FBZLfqWe9F0Yt9JOREm4xhQQg096ucTvKIS3oJ5r2')}&countryCode=${this.#options.lang}`
    let params = this.getParams()
    let xhr = api.saveData(url)
    this.showLoading()
    xhr.onload = function(e) {
      that.closeLoading()
      if(this.status === 200) {
        const res = JSON.parse(this.response)
        if(res.msgCode === 0) {
          cb(res.data)
        }else {
          alert(res.message)
        }
      }
    }
    xhr.onerror = function(e) {
      that.closeLoading()
      throw new Error(e.stausText)
    }
    xhr.send(JSON.stringify(params))
  }
  submit(cb) {
    let that = this
    let url = this.#options.rootURL + `/power/estimateUserRecord/saveUserRecord?gwcredentials=${encodeURIComponent('mOg7w15h2iWWTtLi0cb18NSxUdDmSOawSsWUuTpHZHLD+kj1RTS/tE8FBZLfqWe9F0Yt9JOREm4xhQQg096ucTvKIS3oJ5r2')}&countryCode=${this.#options.lang}`
    let params = this.getParams('submit')
    let xhr = api.saveData(url)
    this.showLoading()
    xhr.onload = function(e) {
      that.closeLoading()
      if(this.status === 200) {
        const res = JSON.parse(this.response)
        if(res.msgCode === 0) {
          cb(res.data)
        }else {
          alert(res.message)
        }
      }
    }
    xhr.onerror = function(e) {
      that.closeLoading()
      throw new Error(e.stausText)
    }
    xhr.send(JSON.stringify(params))
  }
  getPDF() {
    let id = this.#select.id
    let that = this
    function getQueryString(name) {
      let reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
      let r = window.location.search.substr(1).match(reg);
      if (r != null) {
        return decodeURIComponent(r[2]);
      }
      return null;
    }
    if(id) {
      let countryCode = this.#options.lang
      // this.#options.lang === 'us' && (countryCode = 'en')
      let url = this.#options.rootURL + `/hes/estimate/download?gwcredentials=${encodeURIComponent('trSj4G2IjfGqUz7TS3ANkZdjXz+GblpX5mA07JP1zfX9BH8uB0o25X3PHcavf3ns53DY6Slqfoc=')}`
      let params = Object.assign({},this.#downloadParams,{uid: id,countryCode: countryCode})
      if (this.#options.siteId) {
        params['siteId'] = this.#options.siteId;
      }
      let xhr = api.getPDF(url)
      this.showLoading()
      xhr.onload = function(e) {
        that.closeLoading()
        const res = JSON.parse(this.response)
        if (!res.msgCode) {
          let url =
            res.data +
            `&name=${that.#select.lang.BLUETTIPowerSolution}.pdf&Content-Disposition=attachment`
          //判断是否在app内打开
          let agent = that.#select.isAPP
          if (agent === 'app') {
            dsBridge && dsBridge.call('openWithBrowser', url)
          } else {
            let local = window.open(url)
            if (!local) {
              const a = document.createElement('a')
              a.href = url
              document.body.appendChild(a)
              a.click()
              document.body.removeChild(a)
            }
          }
        } else {
          alert(res.message)
        }
      }
      xhr.onerror = function(e) {
        that.closeLoading()
        throw new Error(e.stausText)
      }
      xhr.send(JSON.stringify(params))
    }else {
      alert(this.#select.lang.idNotExist)
    }
  }
  getParams(isSubmit) {
    let params = {}
    let lang = this.#select.lang
    if(isSubmit !== 'submit') {
      let mode = this.#select.mode
      let typeObj = JSON.parse(JSON.stringify(this.#select.typeObj))
      let sceneType = Number(typeObj.sceneType)
      let stepData = JSON.parse(JSON.stringify(this.#select.stepData))
      delete stepData.id
      let sum = JSON.parse(JSON.stringify(this.#select.sum))
      let powerEstimateUserScene = Object.assign({},typeObj)
      powerEstimateUserScene.sceneId = powerEstimateUserScene.id
      delete powerEstimateUserScene.id
      delete powerEstimateUserScene.subScenes
      delete powerEstimateUserScene.recommendExpendProBOS
      let scene = JSON.parse(JSON.stringify(stepData.step1.sceneData))
      scene.subSceneId = scene.id
      delete scene.id
      powerEstimateUserScene.powerEstimateUserSubScene = scene
      delete powerEstimateUserScene.powerEstimateUserSubScene.subLoads
      let powerEstimateUserRecommends = []
      let group = JSON.parse(JSON.stringify(this.#select.recommendationGroup[mode]))
      let product = JSON.parse(JSON.stringify(this.#select.choose[mode].product))
      let _product = JSON.parse(JSON.stringify(Object.assign(product,{
        quantity: group.mainCount,
        discountTotal: Math.ceil(group.productPrice * 100) / 100,
        recommendProductId: product.id
      })))
      delete _product.batteryPacks
      delete _product.solarPanels
      delete _product.sceneId
      delete _product.id
      let condition = product.discountCondition
      if(condition && group.mainCount >= condition) {
        _product.total = Math.ceil(group.productPrice * 100) / 100
        _product.discountTotal = Math.ceil(group.discountPrice * group.mainCount * 100) / 100
      }
      _product.capacity = (_product.capacity * _product.quantity )
      _product.capacityUnit = 'Wh'
      _product.ratedPower = ( _product.ratedPower * _product.quantity )
      _product.ratedPowerUnit = 'W'
      _product.peakPower = ( _product.peakPower * _product.quantity )
      _product.peakPowerUnit = 'W'
      powerEstimateUserRecommends.push(_product)
      let hasBattery = group.hasBattery
      if(hasBattery && product.batteryPacks?.length) {
        let battery = JSON.parse(JSON.stringify(this.#select.choose[mode].battery))
        let groupBatteryCount = group.batteryCount ?? 0
        let bonusBatteryCount = this.#select.bonus[mode].battery.has ? this.#select.bonus[mode].battery.count : 0
        let sumBatteryCount = groupBatteryCount + bonusBatteryCount
        if(sumBatteryCount) {
          let _battery = Object.assign(battery, {
            quantity: sumBatteryCount,
            discountTotal: Math.ceil(battery.productPrice * sumBatteryCount * 100) / 100,
            recommendProductId: battery.id
          })
          let batteryCondition = battery.discountCondition
          if(batteryCondition && sumBatteryCount >= batteryCondition) {
            _battery.total = Math.ceil(battery.productPrice * sumBatteryCount * 100) / 100
            _battery.discountTotal = Math.ceil(battery.discountPrice * sumBatteryCount * 100) / 100
          }
          _battery.capacity = (_battery.capacity * _battery.quantity)
          _battery.capacityUnit = 'Wh'
          powerEstimateUserRecommends.push(_battery)
        }
      }
      let hasPanel = this.#select.bonus[mode].panel.has
      if(hasPanel) {
        let panel = JSON.parse(JSON.stringify(this.#select.choose[mode].panel.filter(item => item.recommendSubId === this.#select.bonus[mode].panel.id)[0]))
        let panelCount = this.#select.bonus[mode].panel.count
        let _panel = Object.assign(panel,{
          quantity: panelCount,
          discountTotal: Math.ceil(panel.productPrice * panelCount * 100) / 100,
          recommendProductId: panel.id
        })
        let panelCondition = panel.discountCondition
        if(panelCondition && panelCount >= panelCondition) {
          _panel.total = Math.ceil(panel.productPrice * panelCount * 100) / 100
          _panel.discountTotal = Math.ceil(panel.discountPrice * panelCount * 100) / 100
        }
        _panel.ratedPower = (_panel.ratedPower * panelCount)
        _panel.ratePowerUnit = 'W'
        powerEstimateUserRecommends.push(_panel)
      }
      if(mode === 'wizard') {
        let powerEstimateUserLoadTypeTOS = []
        let operType = Number(scene.operType)
        if(operType === 1) {
          //选择负载
          let subLoads = JSON.parse(JSON.stringify(stepData.step1.sceneData.subLoads))
          subLoads.forEach(item => {
            let isAdditional = item.isAdditional
            if(!isAdditional) {
              let id = item.id
              delete item.id
              let sum = this.#select.calculate[id]
              let loads = item.powerEstimateEquipments
              loads.forEach(_item => {
                _item.equipmentId = _item.id
                _item.userLoadTypeId = id
                delete _item.id
              })
              let result = {
                backupEnergy: sum.backupEnergy,
                backupEnergyUnit: 'kWh',
                backupPower: sum.backupPower,
                backupPowerUnit: 'kW',
                surgePower: sum.surgePower,
                surgePowerUnit: 'kW',
                powerEstimateUserEquipments: loads,
                isCustom: false,
                loadTypeId: id,
                order: item.order,
                userLoadTypeDesc: item.desc,
                userLoadTypeName: item.name
              }
              powerEstimateUserLoadTypeTOS.push(result)
            }
          })
        }
        if(sceneType === 3) {
          // 家庭
          powerEstimateUserScene.powerEstimateUserSubScene.name = Number(stepData.step1.hes) === 1 ? lang.onlyHES : lang.HESWithPanel
          params = {
            userRecordTO: {
              bill: stepData.step1.bill,
              shutdownBackup: stepData.step1.hour,
              dailyConsumption: stepData.step1.perDay,
              dailyConsumptionUnit: 'kWh',
              power: sum.backupPower,
              hes: stepData.step1.hes,
              fee: stepData.step1.fee,
              powerUnit: 'kW',
              capacity: sum.backupEnergy,
              capacityUnit: 'kWh',
              peakPower: sum.surgePower,
              peakPowerUnit: 'kW',
              warning: [],
              powerEstimateUserScene: powerEstimateUserScene,
              powerEstimateUserLoadTypeTOS: powerEstimateUserLoadTypeTOS,
              powerEstimateUserRecommends: powerEstimateUserRecommends
            },
            userRelation: {
              address: stepData.step1.zip
            }
          }
        }else {
          // 非家庭
          params = {
            userRecordTO: {
              powerEstimateUserScene: powerEstimateUserScene,
              powerEstimateUserLoadTypeTOS: powerEstimateUserLoadTypeTOS,
              powerEstimateUserRecommends: powerEstimateUserRecommends
            }
          }
          if(operType === 2) {
            //参考负载
            Object.assign(params.userRecordTO, {
              capacity: (stepData.step2.capacity ?? 0),
              capacityUnit: 'Wh',
              power: (stepData.step2.power ?? 0),
              powerUnit: 'W'
            })
          }else {
            // 选择负载
            Object.assign(params.userRecordTO, {
              power: sum.backupPower,
              powerUnit: 'kW',
              capacity: sum.backupEnergy,
              capacityUnit: 'kWh',
              peakPower: sum.surgePower,
              peakPowerUnit: 'kW'
            })
          }
          let isCamping = scene.isCamping
          if(isCamping === 'camping') {
            // Object.assign(params.userRecordTO, {
            //   howMuchToUse: stepData.step1.people
            // })
          }else if(isCamping === 'other'){
            Object.assign(params.userRecordTO, {
              howToUse: stepData.step1.other
            })
          }
        }
      }else if(mode === 'userDefined') {
        // 自定义
        if(sceneType === 3) {
          powerEstimateUserScene.powerEstimateUserSubScene.name = Number(stepData.step1.hes) === 1 ? lang.onlyHES : lang.HESWithPanel
        }
        params = {
          userRecordTO: {
            powerEstimateUserScene: powerEstimateUserScene,
            powerEstimateUserRecommends: powerEstimateUserRecommends,
            capacity: stepData.step1.capacity,
            capacityUnit: 'Wh',
            power: stepData.step1.power,
            powerUnit: 'W'
          }
        }
      }
      this.#select.id && (params.id = this.#select.id)
    }else {
      params = {
        id: this.#select.id,
        userRelation: {
          address: this.#select.user.zip,
          email: this.#select.user.email,
          name: this.#select.user.name,
          phoneNumber: this.#select.user.phone
        }
      }
    }
    Object.assign(params, {
      countryCode: this.#options.lang,
      currency: this.#select.currency
    })
    this.#options.siteId && (params.siteId = this.#options.siteId)
    this.#options.channel && (params.channel = this.#options.channel)
    this.#options.franchiserUid && (params.franchiserUid = this.#options.franchiserUid)
    return params
  }
  eventBind() {
    this.homeListEventBind()
  }
  homeListEventBind() {
    const that = this
    this.find('.BDesign__home-list').addEventListener('click',function(e) {
      let el = e.target
      while(el && !el.classList.contains('BDesign__home-item') && !el.classList.contains('BDesign__home-list')) {
        el = el.parentNode
      }
      if(el && el.classList.contains('BDesign__home-item')) {
        that.#select.typeID = el.dataset.id
        that.#select.typeObj = that.#data.homeList.filter(item => item.id === el.dataset.id)[0]
        if(that.#select.typeObj.subScenes.length) {
          that.step = 1
          that.#select.mode = 'wizard'
          that.#select.step.userDefined = 1
          let subSceneId = that.#select.typeObj.subScenes[0].id
          let subSceneObj = that.#select.typeObj.subScenes[0]
          if(that.#select.homeData.subSceneId) {
            const obj = that.#select.typeObj.subScenes.find(item => item.id === that.#select.homeData.subSceneId)
            if(obj) {
              subSceneId = that.#select.homeData.subSceneId
              subSceneObj = obj
            }
          }
          that.#select.stepData.step1.sceneID = subSceneId
          that.#select.stepData.step1.sceneData = subSceneObj
          that.find('.BDesign__footer').classList.remove("show")
          that.renderStepTemplate()
        }else {
          alert(that.#select.lang.subSceneNotExist)
        }
      }
    },false)
  }
  stepCommonEventBind() {
    const that = this
    let tabs = this.find('.BDesign__step-tabs')
    let lang = this.#select.lang
    tabs && tabs.addEventListener('click',function(e) {
      let el = e.target
      while(el && !el.classList.contains('BDesign__step-tab') && !el.classList.contains('BDesign__step-tabs')) {
        el = el.parentNode
      }
      if(el && el.classList.contains('BDesign__step-tab')) {
        that.findAll('.BDesign__step-tab').forEach(item => item.classList.remove('active'))
        el.classList.add('active')
        let mode = el.dataset.mode
        that.#select.mode = mode
        let step = that.#select.step[mode]
        that.showSum()
        that.find('.BDesign__step-list .BDesign__step-list--box').innerHTML = render.getStepListTemplate(mode,step,lang).fragment
        that.find(".BDesign__step-list .BDesign__step-label--box").innerHTML = render.getStepListTemplate(mode,step,lang).labels
        that.findAll('.BDesign__step-wrapper').forEach(item => item.classList.remove('show'))
        let wrapper = that.find(`.BDesign__step-wrapper.${mode}`)
        wrapper.classList.add('show')
      }
    },false)
    this.find('.BDesign__step-back--box').addEventListener('click',function(e) {
      let max = {
        wizard: 5,
        userDefined : 4
      }
      let mode = that.#select.mode
      let step = that.#select.step[mode]
      if(step === max[mode]) {
        that.clearData()
        that.init()
      }else {
        that.step --
        step = that.#select.step[mode]
        if(step === 0) {
          that.find('.BDesign__step-container').classList.remove('show')
          that.find('.BDesign__home-wrapper').classList.add("show")
        }else {
          that.showSum()
          that.find(`.BDesign__step-wrapper.${that.#select.mode} .BDesign__step[step${that.#select.step[that.#select.mode] + 1}]`).classList.remove('show')
          that.find(`.BDesign__step-wrapper.${that.#select.mode} .BDesign__step[step${that.#select.step[that.#select.mode]}]`).classList.add('show')
        }
      }
    },false)
    let btn = this.find(".BDesign__sum .step-next__btn")
    btn && btn.addEventListener('click',function(e) {
      if(!this.classList.contains('warning')) {
        let mode = that.#select.mode
        let nextWrapper = that.find(".BDesign__step-wrapper.wizard .BDesign__step[step3]")
        let step2 = that.find(".BDesign__step-wrapper.wizard .BDesign__step[step2]")
        that.calculateSum()
        that.getRecommend()
        if(!that.#select.recommendation[mode].length) {
          let type = Number(that.#select.stepData.step1.sceneData.operType)
          let text = lang.toManyApplication
          type === 2 && (text = lang.valueTooLarge)
          alert(text)
        } else {
          that.step ++
          nextWrapper.innerHTML = render.getStepBoxTemplate(that.#select)
          that.wizardStep3EventBind()
          nextWrapper.classList.add('show')
          step2.classList.remove('show')
          that.find('.BDesign__sum').classList.remove("show")
        }
      }
    },false)
    let warningIcon = this.find('.BDesign__warning-icon')
    warningIcon && (warningIcon.addEventListener('mouseenter',function(e) {
      that.find('.BDesign__warning-wrapper').classList.add('show')
      this.classList.add('warning')
    }),warningIcon.addEventListener('mouseleave',function(e) {
      that.find('.BDesign__warning-wrapper').classList.remove('show')
      this.classList.remove('warning')
    }),warningIcon.addEventListener('click',function(e) {
      that.find('.BDesign__warning-wrapper').classList.toggle('show')
      this.classList.toggle('warning')
    },false))
  }
  stepEventBind() {
    let mode = this.#select.mode;
    let step = this.#select.step[this.#select.mode];
    this[`${mode}Step${step}EventBind`]()
  }
  nextBtnEventBind() {
    let mode = this.#select.mode
    let step = this.#select.step[mode]
    let that = this
    let nextBtn = this.find(`.BDesign__step-wrapper.${mode} .BDesign__step[step${step}] .BDesign__step${step}-next`)
    nextBtn && nextBtn.addEventListener('click',function(e) {
      let mode = that.#select.mode
      // 页面校验
      if(that.checkValidate()) {
        that.step ++
        let _step = that.#select.step[mode]
        let step = that.find(`.BDesign__step-wrapper.${mode} .BDesign__step[step${_step}]`)
        step.innerHTML = render.getStepBoxTemplate(that.#select)
        that.find(`.BDesign__step-wrapper.${mode} .BDesign__step[step${_step - 1}]`).classList.remove('show')
        step.classList.add("show")
        that.stepEventBind()
      }
    },false)
  }
  wizardStep1EventBind() {
    let data = this.#select.typeObj
    let nextWrapper = this.find('.BDesign__step-wrapper.wizard .BDesign__step[step2]')
    let step1Wrapper = this.find(`.BDesign__step-wrapper.wizard .BDesign__step[step1]`)
    let screenMode = this.#select.screenMode
    let that = this
    let nextBtn = this.find(`.BDesign__step-wrapper.wizard .BDesign__step[step1] .BDesign__step1-next`)
    let lang = this.#select.lang
    nextBtn && nextBtn.addEventListener('click',function(e) {
      if(that.checkValidate()) {
        let sceneData = that.#select.stepData.step1.sceneData
        let type = Number(sceneData.operType)
        if(type === 1) {
          //选择负载
          let applicationLength = 0
          let subLoads = sceneData.subLoads
          sceneData.subLoads.forEach(item => {
            applicationLength += item.powerEstimateEquipments.filter(item => item.selected).length
          })
          if(applicationLength) {
            that.calculateSum()
            that.step ++
            nextWrapper.innerHTML = render.getStepBoxTemplate(that.#select)
            nextWrapper.classList.add('show')
            nextWrapper.style.paddingTop = '0'
            step1Wrapper.classList.remove('show')
            that.getRecommend()
            that.sumChange()
          }else {
            alert(lang.loadIsEmpty)
          }
        }else if(type === 2) {
          // 参考负载
          that.step ++
          nextWrapper.innerHTML = render.getStepBoxTemplate(that.#select)
          nextWrapper.classList.add('show')
          nextWrapper.style.paddingTop = '16px'
          nextWrapper.style.paddingBottom = '16px'
          step1Wrapper.classList.remove('show')
        }
        that.wizardStep2EventBind()
      }
    },false)
    if(data.sceneType !== 3) {
      //非家庭
      this.find('.BDesign__step[step1] .BDesign__scene-list').addEventListener('click', function(e) {
        let el = e.target;
        while(el && !el.classList.contains('BDesign__scene-item') && !el.classList.contains('BDesign__scene-list')) {
          el = el.parentNode
        }
        if(el && el.classList.contains('BDesign__scene-item') && !el.classList.contains('active')) {
          el.parentNode.querySelectorAll('.BDesign__scene-item').forEach(item => item.classList.remove('active'))
          let id = el.dataset.id
          el.classList.add("active")
          that.#select.stepData.step1.sceneID = id
          that.#select.stepData.step1.sceneData = that.#select.typeObj.subScenes.filter(item => item.id === id)[0]
          let isCamping = that.#select.stepData.step1.sceneData?.isCamping
          let camping = that.find(`.BDesign__step-wrapper.${that.#select.mode} .BDesign__step[step${that.#select.step[that.#select.mode]}] .BDesign__step-item.camping`)
          camping.innerHTML = render.getStep1ItemTemplate(that.#select.stepData.step1.sceneData,lang)
          if(isCamping === 'camping') {
            delete that.#select.stepData.step1.other
            delete that.#select.stepData.step1.camping
            that.#select.stepData.step1.people = 1
          }else if(isCamping === 'other'){
            delete that.#select.stepData.step1.camping
            delete that.#select.stepData.step1.people
            that.#select.stepData.step1.other = ''
          }else {
            delete that.#select.stepData.step1.other
            delete that.#select.stepData.step1.people
            that.#select.stepData.step1.camping = null
          }
          that.campingEventBind()
        }
      },false)
      this.campingEventBind()
    }else {
      // 家庭
      let zip = this.find('#BDesign__input-zip')
      zip && zip.addEventListener('blur',function(e) {
        let zip = this.value.trim()
        if(zip) {
          this.parentNode.classList.remove('error')
        }else {
          this.parentNode.classList.add("error")
        }
        that.#select.stepData.step1.zip = this.value
      },false)
    }
    let bill = this.find('#BDesign__input-bill')
    bill && bill.addEventListener('blur',function(e) {
      let value = this.value
      const min = that.#dataLimit.bill.min
      const max = that.#dataLimit.bill.max
      const box = that.find(".BDesign__input-box[prop='bill']")
      value = limit.number(value)
      if(value < min || value > max) {
        box.classList.add('error')
      }else {
        box.classList.remove('error')
      }
      this.value = value
      that.#select.stepData.step1.bill = value
      let perDay = calculate.bill(value,that.#select.stepData.step1.fee)
      that.#select.stepData.step1.perDay = perDay
      let per = that.find('.BDesign__bill-value')
      per && (per.innerHTML = perDay + "kWh")
    },false)
    let fee = this.find("#BDesign__input-fee")
    fee && fee.addEventListener('blur',function(e) {
      let value = +limit.number(this.value,10,2)
      !value && (value = that.#dataLimit.fee.default)
      this.value = value
      that.#select.stepData.step1.fee = value
      let perDay = calculate.bill(that.#select.stepData.step1.bill,value)
      that.#select.stepData.step1.perDay = perDay
      let per = that.find('.BDesign__bill-value')
      per && (per.innerHTML = perDay + "kWh")
    })
    let hesList = this.find('.BDesign__hes-wrapper')
    hesList && hesList.addEventListener('click',function(e) {
      let el = e.target;
      while(el && !el.classList.contains('BDesign__hes-item') && !el.classList.contains('BDesign__hes-wrapper')) {
        el = el.parentNode
      }
      if(el && el.classList.contains('BDesign__hes-item') && !el.classList.contains('active')) {
        el.parentNode.querySelectorAll('.BDesign__hes-item').forEach(item => item.classList.remove('active'))
        el.classList.add("active")
        that.#select.stepData.step1.hes = el.dataset.id
      }
    },false)
    let hour = this.find('#BDesign__input-hour')
    hour && hour.addEventListener('blur',function(e) {
      let value = limit.number(this.value)
      this.value = value
      that.#select.stepData.step1.hour = value
    },false)
    let incr = this.find(".BDesign__count-incr")
    incr && incr.addEventListener('click',function(e) {
      let value = Number(that.#select.stepData.step1.hour) + 1
      value = value > that.#dataLimit.hour.max ? that.#dataLimit.hour.max : value
      that.#select.stepData.step1.hour = value
      hour.value = value
    },false)
    let decr = this.find('.BDesign__count-decr')
    decr && decr.addEventListener('click',function(e) {
      let value = Number(that.#select.stepData.step1.hour) - 1
      that.#select.stepData.step1.hour = value >= 0 ? value : 0
      hour.value = value >= 0 ? value : 0
    },false)
  }
  wizardStep2EventBind () {
    let mode = this.#select.mode
    let sceneData = this.#select.stepData.step1.sceneData
    let type = Number(sceneData.operType)
    let lang = this.#select.lang
    let nextWrapper = this.find(".BDesign__step-wrapper.wizard .BDesign__step[step3]")
    let step2 = this.find(".BDesign__step-wrapper.wizard .BDesign__step[step2]")
    let nextBtn = this.find(".BDesign__step-wrapper.wizard .BDesign__step[step2] .BDesign__step2-next")
    let that = this
    nextBtn && nextBtn.addEventListener('click',function(e) {
      let mode = that.#select.mode
      that.getRecommend()
      if(!that.#select.recommendation[mode].length) {
        let type = Number(that.#select.stepData.step1.sceneData.operType)
        let text = lang.toManyApplication
        type === 2 && (text = lang.valueTooLarge)
        alert(text)
      } else {
        that.step ++
        nextWrapper.innerHTML = render.getStepBoxTemplate(that.#select)
        that.wizardStep3EventBind()
        nextWrapper.classList.add('show')
        step2.classList.remove('show')
      }
    },false)
    if(type === 1) {
      // 选择负载
      this.applicationEventBind()
    }else if(type === 2) {
      // 参考负载
      this.#select.stepData.step2.capacity = sceneData.capacity
      this.#select.stepData.step2.power = sceneData.power
      let capacity = this.find(`.BDesign__step-wrapper.wizard .BDesign__step[step2] #BDesign__input--step2-capacity`)
      capacity && capacity.addEventListener('blur',function(e) {
        let value = limit.number(this.value,10,0)
        this.value = value
        that.#select.stepData.step2.capacity = value
      },false)
      let power = this.find('.BDesign__step-wrapper.wizard .BDesign__step[step2] #BDesign__input--step2-power')
      power && power.addEventListener('blur',function(e) {
        let value = limit.number(this.value,10,0)
        this.value = value
        that.#select.stepData.step2.power = value
      },false)
    }
  }
  wizardStep3EventBind (){
    this.recommendEventBind()
  }
  wizardStep4EventBind () {
    let that = this
    let mode = this.#select.mode
    let step = this.#select.step[mode]
    let lang = this.#select.lang
    let wrapper = this.find(`.BDesign__step-wrapper.${mode} .BDesign__step[step${step}]`)
    let name = wrapper.querySelector('#BDesign__input-name')
    name && name.addEventListener('blur',function(e) {
      let name = this.value.trim()
      if(name) {
        this.parentNode.classList.remove("error")
      }else {
        this.parentNode.classList.add('error')
      }
      that.#select.user.name = this.value
    },false)
    let email = wrapper.querySelector('#BDesign__input-email')
    email && email.addEventListener('blur',function(e) {
      let email = this.value.trim()
      let inputBox = this.parentNode
      let reg = /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;
      if(!email) {
        inputBox.classList.add('error')
        inputBox.querySelector('.BDesign__error-message').innerHTML = lang.pleaseEnterYourEmail
      }else if(!reg.test(email)) {
        inputBox.classList.add('error')
        inputBox.querySelector('.BDesign__error-message').innerHTML = lang.emailFormatError
      }else {
        inputBox.classList.remove('error')
      }
      that.#select.user.email = email
    },false)
    let phone = wrapper.querySelector('#BDesign__input-phone')
    phone && phone.addEventListener('blur',function(e) {
      that.#select.user.phone = this.value
    },false)
    let zip = wrapper.querySelector('#BDesign__input-code')
    zip && zip.addEventListener('blur',function(e) {
      that.#select.user.zip = this.value
    },false)
    let download = wrapper.querySelector('.BDesign__submit-title--download')
    download && download.addEventListener('click',function(e) {
      that.getPDF()
    },false)
    // 在App内无网模式-跳转注册页
    if(that.#select.isAPP === 'app') {
      const toRegisterPage = wrapper.querySelector('.BDesign__submit-login');
      if (toRegisterPage) {
        toRegisterPage.addEventListener('click',function(e) {
          dsBridge && dsBridge.call('openRegisterView', "")
        },false)
      }
    }
    let view = wrapper.querySelectorAll('.BDesign__submit-view')
    view.length && view.forEach(item => {
      item.addEventListener('click',function(e) {
        let url = this.dataset.url
        if(url) {
          if(that.#select.isAPP === 'app') {
            dsBridge && dsBridge.call('openWithBrowser', url)
          }else {
            window.open(url)
          }
        }else {
          alert(lang.productLink)
        }
      },false)

    })
    let agree = wrapper.querySelector(`#BDesign__${mode}-agree`)
    agree && agree.addEventListener('change',function(e) {
      that.#select.user.agree = this.checked
      let tips = wrapper.querySelector('.BDesign__agree-tips')
      if(this.checked) {
        tips.classList.remove("show")
      }
    },false)
    let submit = wrapper.querySelector('.BDesign__submit-contact--submit')
    submit && submit.addEventListener('click',function(e) {
      if(that.#select.channel === 'BS') {
        that.#select.user.agree = true
      }else {
        that.#select.user.agree = wrapper.querySelector(`#BDesign__${mode}-agree`).checked
      }
      that.#select.user.name = wrapper.querySelector('#BDesign__input-name').value
      that.#select.user.email = wrapper.querySelector('#BDesign__input-email').value
      that.#select.user.phone = wrapper.querySelector('#BDesign__input-phone').value
      that.#select.user.zip = wrapper.querySelector('#BDesign__input-code').value
      if(!that.#select.user.agree) {
        let tips = wrapper.querySelector('.BDesign__agree-tips')
        tips.classList.add("show")
      }else if(that.checkValidate()) {
        let mode = that.#select.mode
        that.submit(() => {
          that.step ++
          let _step = that.#select.step[mode]
          let step = that.find(`.BDesign__step-wrapper.${mode} .BDesign__step[step${_step}]`)
          step.innerHTML = render.getStepBoxTemplate(that.#select)
          that.find(`.BDesign__step-wrapper.${mode} .BDesign__step[step${_step - 1}]`).classList.remove('show')
          step.classList.add("show")
          that.stepEventBind()
        })
      }
    },false)
  }
  wizardStep5EventBind () {
    let mode = this.#select.mode
    let step = this.#select.step[mode]
    let that = this
    let wrapper = this.find(`.BDesign__step-wrapper.${mode} .BDesign__step[step${step}]`)
    let btn = wrapper.querySelector('.BDesign__final-btn')
    btn && btn.addEventListener('click',function(e) {
      that.#select.id = ''
      that.clearData()
      that.init()
    },false)
    let download = wrapper.querySelector('.BDesign__final-download')
    download && download.addEventListener('click',function(e) {
      that.getPDF()
    })
  }
  userDefinedStep1EventBind() {
    let that = this
    let mode = this.#select.mode
    let step = this.#select.step[mode]
    let lang = this.#select.lang
    let wrapper = this.find(`.BDesign__step-wrapper.userDefined .BDesign__step[step1]`)
    let nextWrapper = this.find(".BDesign__step-wrapper.userDefined .BDesign__step[step2]")
    let power = wrapper.querySelector('#BDesign__input--power')
    power && power.addEventListener('blur',function(e) {
      let power = limit.number(this.value,10,0)
      that.#select.stepData.step1.power = power
      this.value = power
    },false)
    let capacity = wrapper.querySelector('#BDesign__input--capacity')
    capacity && capacity.addEventListener('blur',function(e) {
      let capacity = limit.number(this.value,10,0)
      that.#select.stepData.step1.capacity = capacity
      this.value = capacity
    },false)
    let btn = wrapper.querySelector('.BDesign__step1-next')
    btn && btn.addEventListener('click',function(e) {
      let mode = that.#select.mode
      that.#select.stepData.step1.capacity = wrapper.querySelector('#BDesign__input--capacity').value
      that.#select.stepData.step1.power = wrapper.querySelector('#BDesign__input--power').value
      that.getRecommend()
      if(!that.#select.recommendation[mode].length) {
        let text = lang.valueTooLarge
        alert(text)
      } else {
        that.step ++
        nextWrapper.innerHTML = render.getStepBoxTemplate(that.#select)
        that.userDefinedStep2EventBind()
        nextWrapper.classList.add('show')
        wrapper.classList.remove('show')
      }
    },false)
  }
  userDefinedStep2EventBind () {
    this.recommendEventBind()
  }
  userDefinedStep3EventBind (){
    this.wizardStep4EventBind()
  }
  userDefinedStep4EventBind () {
    this.wizardStep5EventBind()
  }
  campingEventBind() {
    let that = this
    let select = this.find('#step1__people')
    select && select.addEventListener('change',function(e) {
      that.#select.stepData.step1.people = this.value
    },false)
    let other = this.find('#step1__other')
    other && other.addEventListener('blur',function(e) {
      let value = String(this.value).slice(0,64)
      that.#select.stepData.step1.other = value
      this.value = value
    },false)
  }
  applicationEventBind() {
    let that = this
    let data = this.#select.typeObj
    let mode = this.#select.mode
    let step = this.#select.step[mode]
    let screenMode = this.#select.screenMode
    let sceneData = data.subScenes.filter(item => item.id === this.#select.stepData.step1.sceneID)[0]
    let selectStr = `.BDesign__step-wrapper.${mode} .BDesign__step[step${step}] `
    let editBtn = this.findAll(`${selectStr}.BDesign__application-edit`)
    editBtn.length && editBtn.forEach(item => {
      item.addEventListener('click',function(e) {
        let main = this.parentNode.parentNode
        if(screenMode === 'phone') {
          main = this.parentNode.parentNode.parentNode.querySelector('.BDesign__application-main')
        }
        main.classList.toggle('edit')
      },false)
    })
    let ownList = this.findAll(`${selectStr}.BDesign__checkbox`)
    ownList.length && ownList.forEach(item => {
      item.addEventListener('change',function(e) {
        let value = this.checked
        let line = this.parentNode.parentNode.parentNode.parentNode
        let wrapper = line.parentNode.parentNode.parentNode
        let parentId = wrapper.dataset.id
        let id = line.dataset.id
        let backup = that.#select.stepData.step1.sceneData.backup
        let parentData = sceneData.subLoads.filter(item => item.id === parentId)[0]
        let _data = parentData.powerEstimateEquipments.filter(item => item.id === id)[0]
        let svgBox = line.querySelector('.application-item__svg-box')
        let count = line.querySelector('.appliance__qty-count')
        screenMode === 'phone' && (count = line.querySelector('.BDesign__application-tr--hidden .appliance__qty-count'))
        let countLabel = line.querySelector('.application__item-qty--count')
        let perDayText = ''
        if(value) {
          svgBox.classList.add('active')
          _data.own = true
          count.value = 1
          countLabel.innerHTML = 1
          _data.qty = 1
          let operatingPower = _data.operPower
          if (Number(_data.operPowerUnit) === 2) {
            operatingPower = 1.12 * 1000 * operatingPower
          } else if (Number(line.operPowerUnit) === 3) {
            operatingPower = 0.75 * 1000 * operatingPower
          }
          let perDay =
            Math.ceil((operatingPower * _data.dailyUsageValue * _data.qty) / 10) / 100
          if (Number(_data.dailyUsageUnit) === 2) {
            //电车
            perDay =
              Math.ceil((2.5 * 1000 * _data.dailyUsageValue * _data.qty) / 10) / 100
          }
          perDayText = `${perDay} kWh`
        }else {
          svgBox.classList.remove('active')
          _data.own = false
          if(backup) {
            _data.backup = false
            line.querySelector('.BDesign__switch').checked = false
          }
          _data.qty = 0

          count.value = 0
          countLabel.innerHTML = 0
          perDayText = '0 kWh'
        }
        line.querySelector('.application-item__per').innerHTML = perDayText;
        that.calculateApplication()
      },false)
    })
    let switchList = this.findAll(`${selectStr}.BDesign__switch`)
    switchList.length && switchList.forEach(item => {
      item.addEventListener('change',function(e) {
        let value = this.checked
        let line = this.parentNode.parentNode.parentNode.parentNode
        let wrapper = line.parentNode.parentNode.parentNode
        let parentId = wrapper.dataset.id
        let id = line.dataset.id
        let parentData = sceneData.subLoads.filter(item => item.id === parentId)[0]
        let _data = parentData.powerEstimateEquipments.filter(item => item.id === id)[0]
        let svgBox = line.querySelector('.application-item__svg-box')
        let count = line.querySelector('.appliance__qty-count')
        screenMode === 'phone' && (count = line.querySelector('.BDesign__application-tr--hidden .appliance__qty-count'))
        let countLabel = line.querySelector('.application__item-qty--count')
        let own = line.querySelector('.BDesign__checkbox')
        if(value) {
          _data.backup = true
          if(!_data.own) {
            // 未选择 则选中
            svgBox.classList.add('active')
            own.checked = true
            _data.own = true
            count.value = 1
            countLabel.innerHTML = 1
            _data.qty = 1
          }
        }else {
          _data.backup = false
        }
        let operatingPower = _data.operPower
        if (Number(_data.operPowerUnit) === 2) {
          operatingPower = 1.12 * 1000 * operatingPower
        } else if (Number(line.operPowerUnit) === 3) {
          operatingPower = 0.75 * 1000 * operatingPower
        }
        let perDay =
          Math.ceil((operatingPower * _data.dailyUsageValue * _data.qty) / 10) / 100
        if (Number(_data.dailyUsageUnit) === 2) {
          //电车
          perDay =
            Math.ceil((2.5 * 1000 * _data.dailyUsageValue * _data.qty) / 10) / 100
        }
        line.querySelector('.application-item__per').innerHTML = `${perDay} kWh`;
        that.calculateApplication()
      },false)
    })
    let countList = this.findAll(`.BDesign__step-wrapper.${mode} .BDesign__step[step${step}] .appliance__qty-count`)
    countList.length && countList.forEach(item => {
      item.addEventListener('blur',function(e) {
        let value = Number(limit.number(this.value,10,0))
        value < 0 && (value = 0)
        let line = this.parentNode.parentNode.parentNode.parentNode.parentNode
        screenMode === 'phone' && (line = line.parentNode)
        let wrapper = line.parentNode.parentNode.parentNode
        let parentId = wrapper.dataset.id
        let id = line.dataset.id
        let backup = that.#select.stepData.step1.sceneData.backup
        let parentData = sceneData.subLoads.filter(item => item.id === parentId)[0]
        let _data = parentData.powerEstimateEquipments.filter(item => item.id === id)[0]
        let svgBox = line.querySelector('.application-item__svg-box')
        let _switch = line.querySelector('.BDesign__switch')
        let own = line.querySelector('.BDesign__checkbox')
        let countLabel = line.querySelector('.application__item-qty--count')
        if(value === 0) {
          _data.qty = 0
          _data.own = false
          if(backup) {
            _data.backup = false
            own.checked = false
          }
          svgBox.classList.remove('active')
          _switch.checked = false
        }else {
          _data.own = true
          _data.qty = value
          own.checked = true
          svgBox.classList.add('active')
        }
        this.value = value
        countLabel.innerHTML = value
        let operatingPower = _data.operPower
        if (Number(_data.operPowerUnit) === 2) {
          operatingPower = 1.12 * 1000 * operatingPower
        } else if (Number(line.operPowerUnit) === 3) {
          operatingPower = 0.75 * 1000 * operatingPower
        }
        let perDay =
          Math.ceil((operatingPower * _data.dailyUsageValue * _data.qty) / 10) / 100
        if (Number(_data.dailyUsageUnit) === 2) {
          //电车
          perDay =
            Math.ceil((2.5 * 1000 * _data.dailyUsageValue * _data.qty) / 10) / 100
        }
        line.querySelector('.application-item__per').innerHTML = `${perDay} kWh`;
        that.calculateApplication()
      },false)
    })
    let addList = this.findAll(`${selectStr}.appliance__qty-add`)
    addList.length && addList.forEach(item => {
      item.addEventListener('click',function(e) {
        let line = this.parentNode.parentNode.parentNode.parentNode.parentNode
        screenMode === 'phone' && (line = line.parentNode)
        let wrapper = line.parentNode.parentNode.parentNode
        let parentId = wrapper.dataset.id
        let id = line.dataset.id
        let parentData = sceneData.subLoads.filter(item => item.id === parentId)[0]
        let _data = parentData.powerEstimateEquipments.filter(item => item.id === id)[0]
        let value = _data.qty + 1
        let count = line.querySelector('.appliance__qty-count')
        screenMode === 'phone' && (count = line.querySelector('.BDesign__application-tr--hidden .appliance__qty-count'))
        let countLabel = line.querySelector('.application__item-qty--count')
        let svgBox = line.querySelector('.application-item__svg-box')
        let own = line.querySelector('.BDesign__checkbox')
        if(value >= 1) {
          svgBox.classList.add('active')
          own.checked = true
          _data.own = true
        }
        _data.qty = value
        let operatingPower = _data.operPower
        if (Number(_data.operPowerUnit) === 2) {
          operatingPower = 1.12 * 1000 * operatingPower
        } else if (Number(line.operPowerUnit) === 3) {
          operatingPower = 0.75 * 1000 * operatingPower
        }
        let perDay =
          Math.ceil((operatingPower * _data.dailyUsageValue * _data.qty) / 10) / 100
        if (Number(_data.dailyUsageUnit) === 2) {
          //电车
          perDay =
            Math.ceil((2.5 * 1000 * _data.dailyUsageValue * _data.qty) / 10) / 100
        }
        line.querySelector('.application-item__per').innerHTML = `${perDay} kWh`;
        count.value = value
        countLabel.innerHTML = value
        that.calculateApplication()
      },false)
    })
    let incrList = this.findAll(`${selectStr}.appliance__qty-incr`)
    incrList.length && incrList.forEach(item => {
      item.addEventListener('click',function(e) {
        let line = this.parentNode.parentNode.parentNode.parentNode.parentNode
        screenMode === 'phone' && (line = line.parentNode)
        let wrapper = line.parentNode.parentNode.parentNode
        let parentId = wrapper.dataset.id
        let id = line.dataset.id
        let backup = that.#select.stepData.step1.sceneData.backup
        let parentData = sceneData.subLoads.filter(item => item.id === parentId)[0]
        let _data = parentData.powerEstimateEquipments.filter(item => item.id === id)[0]
        let value = _data.qty - 1
        value < 0 && (value = 0)
        let svgBox = line.querySelector('.application-item__svg-box')
        let _switch = line.querySelector('.BDesign__switch')
        let own = line.querySelector('.BDesign__checkbox')
        let count = line.querySelector('.appliance__qty-count')
        screenMode === 'phone' && (count = line.querySelector('.BDesign__application-tr--hidden .appliance__qty-count'))
        let countLabel = line.querySelector('.application__item-qty--count')
        if(value === 0) {
          _data.qty = 0
          if(backup) {
            _switch.checked = false
            _data.backup = false
          }
          svgBox.classList.remove('active')
          own.checked = false
          _data.own = false
        }
        _data.qty = value
        let operatingPower = _data.operPower
        if (Number(_data.operPowerUnit) === 2) {
          operatingPower = 1.12 * 1000 * operatingPower
        } else if (Number(line.operPowerUnit) === 3) {
          operatingPower = 0.75 * 1000 * operatingPower
        }
        let perDay =
          Math.ceil((operatingPower * _data.dailyUsageValue * _data.qty) / 10) / 100
        if (Number(_data.dailyUsageUnit) === 2) {
          //电车
          perDay =
            Math.ceil((2.5 * 1000 * _data.dailyUsageValue * _data.qty) / 10) / 100
        }
        line.querySelector('.application-item__per').innerHTML = `${perDay} kWh`;
        count.value = value
        countLabel.innerHTML = value
        that.calculateApplication()
      },false)
    })
    let operatingList = this.findAll(`${selectStr}.appliance__operate-input`)
    operatingList.length && operatingList.forEach(item => {
      item.addEventListener('blur',function(e) {
        let value = limit.number(this.value)
        let line = this.parentNode.parentNode.parentNode
        screenMode === 'phone' && (line = line.parentNode.parentNode)
        let wrapper = line.parentNode.parentNode.parentNode
        let parentId = wrapper.dataset.id
        let id = line.dataset.id
        let parentData = sceneData.subLoads.filter(item => item.id === parentId)[0]
        let _data = parentData.powerEstimateEquipments.filter(item => item.id === id)[0]
        this.value = value
        _data.operPower = value
        that.calculateApplication()
      },false)
    })
    let operateSelect = this.findAll(`${selectStr}.appliance__operate-select`)
    operateSelect.length && operateSelect.forEach(item => {
      item.addEventListener('change',function(e) {
        let unit = this.value
        let line = this.parentNode.parentNode.parentNode
        let count = line.querySelector('.BDesign__application-main--right .appliance__operate-input')
        if(screenMode === 'phone') {
          line = line.parentNode.parentNode
          count = line.querySelector('.BDesign__application-main--left .appliance__operate-input')
        }
        let wrapper = line.parentNode.parentNode.parentNode
        let parentId = wrapper.dataset.id
        let id = line.dataset.id
        let parentData = sceneData.subLoads.filter(item => item.id === parentId)[0]
        let _data = parentData.powerEstimateEquipments.filter(item => item.id === id)[0]
        let power = _data.operPower
        let operating = calculate.operatePower(power,_data.operPowerUnit,unit)
        _data.operPowerUnit = unit
        _data.operPower = operating
        count.value = operating
        that.calculateApplication()
      },false)
    })
    let daily = this.findAll(`${selectStr}.appliance__daily-input`)
    daily.length && daily.forEach(item => {
      item.addEventListener('blur',function(e) {
        let value = limit.number(this.value)
        let line = this.parentNode.parentNode.parentNode.parentNode
        screenMode === 'phone' && (line = line.parentNode.parentNode)
        let wrapper = line.parentNode.parentNode.parentNode
        let parentId = wrapper.dataset.id
        let id = line.dataset.id
        let parentData = sceneData.subLoads.filter(item => item.id === parentId)[0]
        let _data = parentData.powerEstimateEquipments.filter(item => item.id === id)[0];
        (Number(_data.dailyUsageUnit) === 1 && value > 24) && (value = 24)
        _data.dailyUsageValue = value
        this.value = value
        that.calculateApplication()
      })
    })
    let surge = this.findAll(`${selectStr}.appliance__current-select`)
    surge.length && surge.forEach(item => {
      item.addEventListener('change',function(e) {
        let value = this.value
        let line = this.parentNode.parentNode.parentNode
        let input = ''
        let suffix = ''
        if(screenMode === 'phone') {
          line = line.parentNode.parentNode
          input = this.parentNode.nextElementSibling.querySelector('.appliance__current-input')
          suffix = this.parentNode.nextElementSibling.querySelector('.appliance__current-suffix')
        }else {
          input = this.nextElementSibling.querySelector('.appliance__current-input')
          suffix = this.nextElementSibling.querySelector('.appliance__current-suffix')
        }
        let wrapper = line.parentNode.parentNode.parentNode
        let parentId = wrapper.dataset.id
        let id = line.dataset.id
        let parentData = sceneData.subLoads.filter(item => item.id === parentId)[0]
        let _data = parentData.powerEstimateEquipments.filter(item => item.id === id)[0];
        let power = calculate.surge(_data.surgePowerValue,value)
        _data.surgePowerValue = power
        input.value = power
        suffix.innerHTML = that.#select.powerEnum.filter(item => item.id == value)[0].unit
        _data.surgePowerUnit = value
        that.calculateApplication()
      },false)
    })
    let surgeInput = this.findAll(`${selectStr}.appliance__current-input`)
    surgeInput.length && surgeInput.forEach(item => {
      item.addEventListener('blur',function(e) {
        let value = limit.number(this.value)
        let line = this.parentNode.parentNode.parentNode.parentNode
        screenMode === 'phone' && (line = line.parentNode.parentNode)
        let wrapper = line.parentNode.parentNode.parentNode
        let parentId = wrapper.dataset.id
        let id = line.dataset.id
        let parentData = sceneData.subLoads.filter(item => item.id === parentId)[0]
        let _data = parentData.powerEstimateEquipments.filter(item => item.id === id)[0];
        _data.surgePowerValue = value
        this.value = value
        that.calculateApplication()
      })
    })
  }
  recommendRegularEventBind(){
    let that = this
    let mode = this.#select.mode
    let step = this.#select.step[mode]
    let wrapper = this.find(`.BDesign__step-wrapper.${mode} .BDesign__step[step${step}]`)
    let productList = wrapper.querySelectorAll(".BDesign__recommend-item")
    productList.forEach(item => {
      item.addEventListener('click',function(e){
        if(!this.classList.contains('active')) {
          let mode = that.#select.mode
          let index = this.dataset.index
          item.parentNode.querySelectorAll('.BDesign__recommend-item').forEach(_item => _item.classList.remove('active'))
          this.classList.add('active')
          let selectData = that.#select
          selectData.recommendationGroup[mode] = selectData.recommendation[mode][index]
          let group = selectData.recommendationGroup[mode]
          selectData.choose[mode].product = selectData.typeObj.recommendExpendProBOS.filter(item => item.id === selectData.recommendationGroup[mode].mainID)[0]
          if(selectData.choose[mode].product.selectType && selectData.choose[mode].product.batteryPacks.length){
            //产品允许添加电池包
            let id = selectData.recommendationGroup[mode].batteryId
            if(id) {
              // 当前选中组合中有电池
              selectData.choose[mode].battery = selectData.choose[mode].product.batteryPacks.filter(item => item.recommendSubId === selectData.recommendationGroup[mode].batteryId)[0]
            }else {
              //当前选中组合中没有电池包 显示序号小的电池包
              selectData.choose[mode].battery = selectData.choose[mode].product.batteryPacks.sort((a, b) => a.expendOrder - b.expendOrder)[0]
            }
            let max = group.mainCount * selectData.choose[mode].battery.maximum
            let batteryCount = group.batteryCount ? group.batteryCount : 0
            if(batteryCount < max) {
              selectData.bonus[mode].battery.count = 1
              selectData.bonus[mode].battery.has = true
            }else {
              // 组合内电池包数量大于电池包最大值 不显示额外电池包选项
              selectData.bonus[mode].battery.count = 0
              selectData.bonus[mode].battery.has = false
            }
          }else {
            selectData.choose[mode].battery = {}
            selectData.bonus[mode].battery.count = 0;
            selectData.bonus[mode].battery.has = false
          }
          if(selectData.choose[mode].product.solarPanels.length) {
            selectData.choose[mode].panel = selectData.choose[mode].product.solarPanels
            selectData.bonus[mode].panel.count = 1
            selectData.bonus[mode].panel.has = true
            selectData.bonus[mode].panel.id = selectData.choose[mode].panel[0].recommendSubId
          }else {
            selectData.choose[mode].panel = []
            selectData.bonus[mode].panel.count = 0;
            selectData.bonus[mode].panel.has = false;
            selectData.bonus[mode].panel.id = ''
          }
          wrapper.querySelector('.BDesign__recommend-choose--box.battery').innerHTML = render.getBatteryTemplate(selectData)
          wrapper.querySelector('.BDesign__recommend-choose--box.panel').innerHTML = render.getPanelTemplate(selectData)
          that.recommendRegularEventBind()
        }
      },false)
    })
    let select = wrapper.querySelector("#BDesign__recommend-select")
    select && select.addEventListener('change',function(e) {
      let index = this.value
      let mode = that.#select.mode
      wrapper.querySelectorAll('.BDesign__recommend-item').forEach(_item => _item.classList.remove('active'))
      wrapper.querySelector(`.BDesign__recommend-item[data-index='${index}']`).classList.add('active')
      let selectData = that.#select
      selectData.recommendationGroup[mode] = selectData.recommendation[mode][index]
      let group = selectData.recommendationGroup[mode]
      selectData.choose[mode].product = selectData.typeObj.recommendExpendProBOS.filter(item => item.id === selectData.recommendationGroup[mode].mainID)[0]
      if(selectData.choose[mode].product.selectType && selectData.choose[mode].product.batteryPacks.length){
        //产品允许添加电池包
        let id = selectData.recommendationGroup[mode].batteryId
        if(id) {
          // 当前选中组合中有电池
          selectData.choose[mode].battery = selectData.choose[mode].product.batteryPacks.filter(item => item.recommendSubId === selectData.recommendationGroup[mode].batteryId)[0]
        }else {
          //当前选中组合中没有电池包 显示序号小的电池包
          selectData.choose[mode].battery = selectData.choose[mode].product.batteryPacks.sort((a, b) => a.expendOrder - b.expendOrder)[0]
        }
        let max = group.mainCount * selectData.choose[mode].battery.maximum
        let batteryCount = group.batteryCount ? group.batteryCount : 0
        if(batteryCount < max) {
          selectData.bonus[mode].battery.count = 1
          selectData.bonus[mode].battery.has = true
        }else {
          // 组合内电池包数量大于电池包最大值 不显示额外电池包选项
          selectData.bonus[mode].battery.count = 0
          selectData.bonus[mode].battery.has = false
        }
      }else {
        selectData.choose[mode].battery = {}
        selectData.bonus[mode].battery.count = 0;
        selectData.bonus[mode].battery.has = false
      }
      if(selectData.choose[mode].product.solarPanels.length) {
        selectData.choose[mode].panel = selectData.choose[mode].product.solarPanels
        selectData.bonus[mode].panel.count = 1
        selectData.bonus[mode].panel.has = true
        selectData.bonus[mode].panel.id = selectData.choose[mode].panel[0].recommendSubId
      }else {
        selectData.choose[mode].panel = []
        selectData.bonus[mode].panel.count = 0;
        selectData.bonus[mode].panel.has = false;
        selectData.bonus[mode].panel.id = ''
      }
      wrapper.querySelector('.BDesign__recommend-choose--box.battery').innerHTML = render.getBatteryTemplate(selectData)
      wrapper.querySelector('.BDesign__recommend-choose--box.panel').innerHTML = render.getPanelTemplate(selectData)
      that.recommendRegularEventBind()
    },false)
    let battery = wrapper.querySelectorAll('.BDesign__recommend-choose--box.battery .BDesign__recommend-choose--item')
    battery.length && battery.forEach(item => {
      item.addEventListener('click',function(e) {
        if(!this.classList.contains('active')) {
          let mode = that.#select.mode
          let parent = this.parentNode.parentNode
          parent.querySelectorAll('.BDesign__recommend-choose--item').forEach(_item => _item.classList.remove('active'))
          this.classList.add('active')
          let value = this.dataset.value
          let content = parent.querySelector('.BDesign__recommend-choose--content')
          if(value === 'show'){
            content.classList.add('show')
            that.#select.bonus[mode].battery.has = true
          }else if(value === 'hidden') {
            content.classList.remove('show')
            that.#select.bonus[mode].battery.has = false
          }
        }
      },false)
    })
    let addBattery = wrapper.querySelector('.BDesign__recommend-choose--box.battery .BDesign__battery-add')
    addBattery && addBattery.addEventListener('click',function(e) {
      let mode = that.#select.mode
      let count = that.#select.bonus[mode].battery.count
      let group = that.#select.recommendationGroup[mode]
      let battery = that.#select.choose[mode].battery
      let groupCount = group.batteryCount ?? 0
      let max = battery.maximum * that.#select.recommendationGroup[mode].mainCount
      let value = count + 1
      if((value + groupCount) > max) {
        value = max - groupCount
      }
      that.#select.bonus[mode].battery.count = value
      wrapper.querySelector(".BDesign__recommend-choose--box.battery .BDesign__battery-input").value = value
    },false)
    let incrBattery = wrapper.querySelector('.BDesign__recommend-choose--box.battery .BDesign__battery-incr')
    incrBattery && incrBattery.addEventListener('click',function (e) {
      let mode = that.#select.mode
      let count = that.#select.bonus[mode].battery.count
      let value = count - 1
      if(value <= 0) {
        value = 1
      }
      that.#select.bonus[mode].battery.count = value
      wrapper.querySelector(".BDesign__recommend-choose--box.battery .BDesign__battery-input").value = value
    },false)
    let batteryInput = wrapper.querySelector('.BDesign__recommend-choose--box.battery .BDesign__battery-input')
    batteryInput && batteryInput.addEventListener('blur',function(e) {
      let mode = that.#select.mode
      let battery = that.#select.choose[mode].battery
      let groupCount = that.#select.recommendationGroup[mode].batteryCount ?? 0
      let max = battery.maximum * that.#select.recommendationGroup[mode].mainCount
      let value = Number(limit.number(this.value,10,0))
      if((value + groupCount) > max) {
        value = max - groupCount
      }
      that.#select.bonus[mode].battery.count = value
      that.find(".BDesign__recommend-choose--box.battery .BDesign__battery-input").value = value
    },false)
    let viewMore = wrapper.querySelectorAll('.BDesign__recommend-view')
    viewMore.length && viewMore.forEach(item => {
      item.addEventListener('click',function(e) {
        let url = this.dataset.url
        if(url) {
          if(that.#select.isAPP === 'app') {
            dsBridge && dsBridge.call('openWithBrowser', url)
          }else {
            window.open(url)
          }
        }else {
          alert('Product link is not configured')
        }
      },false)
    })
    this.panelEventBind()
  }
  recommendEventBind() {
    let that = this
    let mode = this.#select.mode
    let step = this.#select.step[mode]
    let wrapper = this.find(`.BDesign__step-wrapper.${mode} .BDesign__step[step${step}]`)
    this.recommendRegularEventBind()
    let down = wrapper.querySelector('.BDesign__to-down')
    down && down.addEventListener('click',function(e) {
      that.saveData((id) => {
        let mode = that.#select.mode
        that.#select.id = id
        that.step ++
        let _step = that.#select.step[mode]
        let step = that.find(`.BDesign__step-wrapper.${mode} .BDesign__step[step${_step}]`)
        step.innerHTML = render.getStepBoxTemplate(that.#select)
        that.find(`.BDesign__step-wrapper.${mode} .BDesign__step[step${_step - 1}]`).classList.remove('show')
        step.classList.add("show")
        that.stepEventBind()
      })
    },false)
  }
  panelEventBind() {
    let that = this
    let mode = this.#select.mode
    let step = this.#select.step[mode]
    let lang = this.#select.lang
    let wrapper = this.find(`.BDesign__step-wrapper.${mode} .BDesign__step[step${step}] .BDesign__recommend-choose--box.panel`)
    let panel = wrapper.querySelectorAll('.BDesign__recommend-choose--box.panel .BDesign__recommend-choose--item')
    panel.length && panel.forEach(item => {
      item.addEventListener('click',function(e) {
        if(!this.classList.contains('active')) {
          let mode = that.#select.mode
          let parent = this.parentNode.parentNode
          parent.querySelectorAll('.BDesign__recommend-choose--item').forEach(_item => _item.classList.remove('active'))
          this.classList.add("active")
          let value = this.dataset.value
          let content = parent.querySelector('.BDesign__recommend-choose--content')
          if(value === 'show') {
            content.classList.add("show")
            that.#select.bonus[mode].panel.has = true
          }else if(value === 'hidden') {
            content.classList.remove("show")
            that.#select.bonus[mode].panel.has = false
          }
        }
      },false)
    })
    let select = wrapper.querySelector(".BDesign__recommend-choose--box.panel .BDesign__panel-select")
    select && select.addEventListener('change',function(e) {
      let id = this.value
      let mode = that.#select.mode
      that.#select.bonus[mode].panel = {
        id: id,
        count: 1,
        has: true,
      }
      wrapper.innerHTML = render.getPanelTemplate(that.#select)
      that.panelEventBind()
    },false)
    let add = wrapper.querySelector(".BDesign__recommend-choose--box.panel .BDesign__panel-add")
    add && add.addEventListener('click',function(e) {
      if(!this.classList.contains('disabled')) {
        let mode = that.#select.mode
        let count = that.#select.bonus[mode].panel.count + 1
        let dc = that.#select.choose[mode].product.dcInputVoltage
        let id = that.#select.bonus[mode].panel.id
        let panel = that.#select.choose[mode].panel.filter(item => item.recommendSubId === id)[0]
        let cv = panel.openCircuitVoltage
        let max = parseInt(dc / cv)
        count = count >= max ? max : count
        that.#select.bonus[mode].panel.count = count
        wrapper.querySelector(".BDesign__recommend-choose--box.panel .BDesign__panel-input").value = count
      }
    },false)
    let incr = wrapper.querySelector(".BDesign__recommend-choose--box.panel .BDesign__panel-incr")
    incr && incr.addEventListener('click',function(e) {
      if(!this.classList.contains('disabled')) {
        let mode = that.#select.mode
        let count = that.#select.bonus[mode].panel.count - 1
        count = count <= 0 ? 1 : count
        that.#select.bonus[mode].panel.count = count
        wrapper.querySelector(".BDesign__recommend-choose--box.panel .BDesign__panel-input").value = count
      }
    },false)
    let input = wrapper.querySelector('.BDesign__recommend-choose--box.panel .BDesign__panel-input')
    input && input.addEventListener('blur',function(e) {
      let mode = that.#select.mode
      let count = Number(limit.number(this.value,10,0))
      let dc = that.#select.choose[mode].product.dcInputVoltage
      let id = that.#select.bonus[mode].panel.id
      let panel = that.#select.choose[mode].panel.filter(item => item.recommendSubId === id)[0]
      let cv = panel.openCircuitVoltage
      let max = parseInt(dc / cv)
      if(count >= max) {
        count = max
      }
      if(count <= 0) {
        count = 1
      }
      that.#select.bonus[mode].panel.count = count
      this.value = count
    },false)
    let view = wrapper.querySelectorAll('.BDesign__recommend-view')
    view.forEach(item => {
      item.addEventListener('click',function(e) {
        let url = this.dataset.url
        if(url) {
          if(that.#select.isAPP === 'app') {
            dsBridge && dsBridge.call('openWithBrowser', url)
          }else {
            window.open(url)
          }
        }else {
          alert(lang.productLink)
        }
      },false)
    })
  }
  checkValidate() {
    let validate = true
    let mode = this.#select.mode
    switch(mode) {
      case 'wizard': validate = this.checkWizardValidate();break;
      case 'userDefined': validate = this.checkUserDefinedValidate();break;
      default: validate = true;
    }
    return validate
  }
  checkWizardValidate(){
    let validate = true
    let mode = this.#select.mode
    let step = this.#select.step.wizard
    let lang = this.#select.lang
    let wrapper = this.find(`.BDesign__step-wrapper.${mode} .BDesign__step[step${step}]`)
    let stepData = this.#select.stepData
    //场景类型
    let sceneType = this.#select.typeObj.sceneType
    let sceneData = this.#select.stepData.step1.sceneData
    // 选择负载 / 参考类型
    let type = Number(sceneData.operType)
    if(step === 1) {
      if(sceneType === 3) {
        // 家庭第一步
        let zipValidate = false
        let billValidate = false
        let zip = stepData.step1.zip
        if(zip) {
          zipValidate = true
          wrapper.querySelector(".BDesign__input-box[prop='zip']").classList.remove('error')
        }else {
          zipValidate = false
          wrapper.querySelector(".BDesign__input-box[prop='zip']").classList.add('error')
        }
        let bill = stepData.step1.bill
        let limit = this.#dataLimit.bill
        if(bill < limit.min || bill > limit.max) {
          billValidate = false
          wrapper.querySelector(".BDesign__input-box[prop='bill']").classList.add("error")
        }else {
          billValidate = true
          wrapper.querySelector(".BDesign__input-box[prop='bill']").classList.remove("error")
        }
        validate = zipValidate && billValidate
      }else {
        // 非家庭第一步
        validate = true
      }
    }else if(step === 4) {
      let name = this.#select.user.name ?? ''
      let email = this.#select.user.email ?? ''
      let reg = /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;
      let nameValidate = false
      let emailValidate = false
      if(!name.trim()) {
        nameValidate = false
        wrapper.querySelector(".BDesign__input-box[prop='name']").classList.add('error')
      }else {
        nameValidate = true
        wrapper.querySelector(".BDesign__input-box[prop='name']").classList.remove('error')
      }
      if(!email.trim()) {
        emailValidate = false;
        wrapper.querySelector(".BDesign__input-box[prop='email']").classList.add('error')
        wrapper.querySelector(".BDesign__input-box[prop='email'] .BDesign__error-message").innerHTML = lang.pleaseEnterYourEmail
      }else if(reg.test(email)) {
        emailValidate = true;
        wrapper.querySelector(".BDesign__input-box[prop='email']").classList.remove('error')
      }else {
        emailValidate = false;
        wrapper.querySelector(".BDesign__input-box[prop='email']").classList.add('error')
        wrapper.querySelector(".BDesign__input-box[prop='email'] .BDesign__error-message").innerHTML = lang.emailFormatError
      }
      validate = emailValidate && nameValidate
    }
    return validate
  }
  checkUserDefinedValidate() {
    let validate = true
    let mode = this.#select.mode
    let step = this.#select.step.userDefined
    let lang = this.#select.lang
    let wrapper = this.find(`.BDesign__step-wrapper.${mode} .BDesign__step[step${step}]`)
    if(step === 3) {
      let name = this.#select.user.name ?? ''
      let email = this.#select.user.email ?? ''
      let reg = /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;
      let nameValidate = false
      let emailValidate = false
      if(!name.trim()) {
        nameValidate = false
        wrapper.querySelector(".BDesign__input-box[prop='name']").classList.add('error')
      }else {
        nameValidate = true
        wrapper.querySelector(".BDesign__input-box[prop='name']").classList.remove('error')
      }
      if(!email.trim()) {
        emailValidate = false;
        wrapper.querySelector(".BDesign__input-box[prop='email']").classList.add('error')
        wrapper.querySelector(".BDesign__input-box[prop='email'] .BDesign__error-message").innerHTML = lang.pleaseEnterYourEmail
      }else if(reg.test(email)) {
        emailValidate = true;
        wrapper.querySelector(".BDesign__input-box[prop='email']").classList.remove('error')
      }else {
        emailValidate = false;
        wrapper.querySelector(".BDesign__input-box[prop='email']").classList.add('error')
        wrapper.querySelector(".BDesign__input-box[prop='email'] .BDesign__error-message").innerHTML = lang.emailFormatError
      }
      validate = emailValidate && nameValidate
    }
    return validate
  }
  renderStepTemplate() {
    if(this.#select.typeObj && JSON.stringify(this.#select.typeObj) === '{}')
      throw new Error(this.#select.lang.stepMessageEmpty)
    this.find('.BDesign__home-wrapper').classList.remove('show')
    const stepContainer = this.find('.BDesign__step-container')
    if(this.#select.typeObj.sceneType === 3) {
      if(this.#options.id) {
        let data = this.#select.homeData
        this.#select.stepData.step1.zip = data.address ? data.address: ''
        this.#select.stepData.step1.bill = data.bill ? data.bill : this.#dataLimit.bill.default
        this.#select.stepData.step1.hes = data.hes ? data.hes : 1
        this.#select.stepData.step1.fee = data.fee ? data.fee : this.#dataLimit.fee.default
        this.#select.stepData.step1.perDay = calculate.bill(this.#select.stepData.step1.bill,this.#select.stepData.step1.fee)
        this.#select.stepData.step1.hour = data.shutdownBackup ? data.shutdownBackup : Number(this.#dataLimit.hour.default)
      }else {
        this.#select.stepData.step1.bill = Number(this.#dataLimit.bill.default)
        this.#select.stepData.step1.hes = 1
        this.#select.stepData.step1.fee = this.#dataLimit.fee.default
        this.#select.stepData.step1.perDay = calculate.bill(this.#select.stepData.step1.bill,this.#select.stepData.step1.fee)
        this.#select.stepData.step1.hour = Number(this.#dataLimit.hour.default)
      }
    }else {
      this.#select.stepData.step1.hour = 24
    }
    stepContainer.innerHTML = render.getStepContainerTemplate(this.#select)
    stepContainer.classList.add('show')
    this.stepCommonEventBind()
    this.stepEventBind()
    this.userDefinedStep1EventBind()
  }
  clearData() {
    Object.assign(this.#select,{
      lang: {},
      currency: "USD",
      typeID: '',
      typeObj: {},
      stepData: {
        step1: {},
        step2: {},
        step3: {},
        step4: {}
      },
      mode: 'wizard',
      screenMode: 'pc',
      isAPP: '',
      step: {
        wizard: 0,
        userDefined: 0
      },
      homeData: {},
      calculate: {},
      sum: {
        backupEnergy: 0,
        backupPower: 0,
        surgePower: 0
      },
      choose: {
        wizard: {
          product: {},
          battery: {},
          panel: {}
        },
        userDefined: {
          product: {},
          battery: {},
          panel: {}
        }
      },
      bonus: {
        wizard: {
          battery: {
            has: false,
            count: 1
          },
          panel: {
            has: false,
            count: 1,
            id: ''
          }
        },
        userDefined: {
          battery: {
            has: false,
            count: 1
          },
          panel: {
            has: false,
            count: 1,
            id: ''
          }
        }
      },
      user: {
        agree: false,
        name: '',
        email: '',
        zip: '',
        phone: ''
      },
      recommendation: {
        wizard: [],
        userDefined: {}
      },
      recommendationGroup: {
        wizard: [],
        userDefined: []
      }
    })
  }
  calculateApplication() {
    this.calculateSum()
    this.sumChange()
  }
  //计算电器
  calculateSum() {
    this.#select.calculate = {}
    this.#select.sum = {
      backupEnergy: 0,
      backupPower: 0,
      surgePower: 0,
      load: 0
    }
    let sceneData = this.#select.stepData.step1.sceneData
    let backup = sceneData.backup
    let sceneType = this.#select.typeObj.sceneType
    if(sceneType !== 3) {
      this.#select.stepData.step1.hour = 24
    }
    sceneData.subLoads.forEach(item => {
      let type = item.volumeProduct
      // 0 为 非自定义 1 自定义
      let isAdditional = item.isAdditional
      if(!isAdditional) {
        if(!backup) {
          item.powerEstimateEquipments.forEach(load => load.backup = true)
        }
        this.#select.calculate[item.id] = calculate[type ? type : 'large'](item.powerEstimateEquipments,this.#select.stepData.step1.hour)
      }
    })
    this.#select.sum = calculate.sum(this.#select.calculate)
  }
  // 总数页面变化
  sumChange() {
    let mode = this.#select.mode
    let step = this.#select.step[mode]
    let wrapper = this.find(`.BDesign__step-wrapper.${mode} .BDesign__step[step${step}]`)
    let keys = Object.keys(this.#select.calculate)
    let props = ['backupEnergy','backupPower','surgePower']
    keys.forEach(item => {
      let application = wrapper.querySelector(`.BDesign__application-wrapper[data-id='${item}']`)
      props.forEach(prop => {
        let unit = 'kw'
        prop === 'backupEnergy' && (unit = 'kWh')
        application.querySelector(`.BDesign__application-total--value[${prop}]`).innerHTML = this.#select.calculate[item][prop] + ` ${unit}`
      })
    })
    props.forEach(prop => {
      let unit = 'kw'
      prop === 'backupEnergy' && (unit = 'kWh')
      this.find(`.BDesign__sum .BDesign__sum-value[${prop}]`).innerHTML = this.#select.sum[prop] + ` ${unit}`
    })
    this.setWarning()
  }
  //获取推荐产品信息
  getRecommend() {
    let mode = this.#select.mode
    let recommendList = this.#select.typeObj.recommendExpendProBOS;
    let result = calculate.getRecommend(recommendList,this.#select)
    this.#select.recommendation[mode] = result.list
    this.#select.max = result.max
    this.find('.BDesign__warning-line[backupEnergy] .BDesign__warning-text').innerHTML = `${this.#select.max.backupEnergy} kWh`
    this.find('.BDesign__warning-line[surgePower] .BDesign__warning-text').innerHTML = `${this.#select.max.surgePower} kW`
  }
  setWarning() {
    let mode = this.#select.mode
    let max = this.#select.max
    let sceneType = Number(this.#select.typeObj.sceneType)
    let perDay = this.#select.stepData.step1.perDay
    let sum = this.#select.sum
    let screenMode = this.#select.screenMode
    let line = this.findAll(".BDesign__sum .BDesign__warning-line")
    line.forEach(item => item.classList.remove('show'))
    let wrapper = this.find('.BDesign__sum .BDesign__warning-wrapper')
    let load = this.find('.BDesign__warning-line[load]')
    let energy = this.find('.BDesign__warning-line[backupEnergy]')
    let power = this.find('.BDesign__warning-line[surgePower]')
    let btn = this.find(".BDesign__sum .step__sum-btn")
    wrapper.classList.remove('show')
    let showWarning = false
    let showEnergy = sum.backupEnergy > max.backupEnergy
    if(showEnergy) {
      energy.classList.add('show')
    }else {
      energy.classList.remove('show')
    }
    let showPower = sum.surgePower > max.surgePower
    if(showPower){
      power.classList.add('show')
    }else {
      power.classList.remove("show")
    }
    showWarning = showEnergy || showPower
    if(sceneType === 3) {
      // 户储类型
      let showLoad = sum.load > perDay
      if(showLoad) {
        load.classList.add('show')
      }else {
        load.classList.remove('show')
      }
      showWarning = showLoad || showEnergy || showPower
    }
    if(showWarning) {
      if(screenMode === 'phone') {
        this.find(".BDesign__warning-icon").classList.add('show')
      }else {
        wrapper.classList.add('show')
      }
      btn.classList.add('warning')
    }else {
      if(screenMode === 'phone') {
        this.find(".BDesign__warning-icon").classList.remove('show')
      }else {
        wrapper.classList.remove("show")
      }
      btn.classList.remove('warning')
    }
  }
}

export default (options) => {
  return new BluettiDesign(options)
}
