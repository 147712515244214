import request from '../request'

export default {
  getHomeList(url,config) {
    return request.get(url, config)
  },
  saveData(url,config) {
    return request.post(url,config)
  },
  getPDF(url,config) {
    return request.post(url,config)
  },
  getLang(url,config) {
    return request.get(url,config)
  }
}
